export default {
  //header
  headTop: 'NEW CUSTOMER {symbol} OFF',
  registNow: 'Register Now',
  liveChart: 'Live Chat',
  liveChartInfo: 'If you have any questions before making a purchase,chat with our online operaters to get more informations.',
  creatTickets: 'Creat a Tickets',
  creatTicketsInfo: 'If you need any help about the after-sales issues,please submit a ticket.',
  currencyInfo: 'We ship our orders worldwide! Switch the store if you want to view in different Currency.',
  billedIn: 'Your order will be billed in:',
  trackMyOrder: 'Track My Order',
  orderNum: 'Order Number',
  account: "Account",
  orders: 'Orders',
  search: 'Search',
  wishlist: 'Wishlist',
  changeCurr: 'Change Currency',
  hotSearch: 'Hot Searched',
  occasion: 'Occasion',
  trendPro: 'Trending Products',
  prom: 'PROM',
  homecoming: 'Homecoming',
  evening: 'Evening',
  bridesmaids: 'Bridesmaids',
  wedding: 'Wedding',
  lang: "Language",
  country: "Country",
  currency: "Currency",
  couRegion: "Country/Region",
  english: 'english',
  spanish: 'spanish',
  france: 'france',
  store: 'Store',
  //home
  shopFullCo: 'SHOP FULL COLLECTION',
  top: 'Top',
  categories: 'Categories',
  slimProm: 'SLIM FIT PROM',
  alineProm: 'A-LINE PROM',
  bridesmaid: 'Bridesmaid',
  moms: 'Moms',
  flowerGirl: 'Flower Girls',
  headpieces: 'Headpieces',
  swatches: 'Swatches',
  motherOf: '2024 Mother of the Bride Dresses',
  motherInfo: 'You can create the perfect mix and play with fabrics for a dreamy wedding vibe.',
  new: 'New',
  arrivals: 'Arrivals',
  arrivalsInfo: "It's All In The Details! Which Is Your Style?",
  viewAllDress: 'VIew All Dresses',
  bridesmaidsMix: 'Bridesmaids Mix & Match',
  bridesmaidsMixInfo: 'Our colors are carefully assorted to make mix & match a breeze',
  inspiration: 'LOOKING FOR INSPIRATION',
  accessoires: 'Accessoires',
  accessoiresInfo: 'Ultra feminine accessories for your big day',
  weddShoes: 'Wedding Shoes',
  shoesInfo: 'Comfort & Elegance, Detail & Style',
  fabrics: 'Swatches & Fabrics',
  fabricsInfo: 'Will help you learn how a specific color looks',
  gallery1: 'Style',
  gallery2: 'Gallery',
  galleryInfo: 'STACEES customers all over the world share their personal style moments with us.',
  galleryInfo1: 'STACEES customers share wounderful moments with us.',
  product: 'VIEW PRODUCT',
  loadMore: 'LOAD MORE',
  viewMore: 'VIEW MORE',
  viewLess: 'VIEW LESS',
  chat: 'Chat With Us',
  chatInfo: 'Our customer service team is standing by and can answer any questions.',
  customiz: 'Customization',
  customizInfo: 'We provide customized services, custom size or exclusive design for your dress.',
  affordable: 'Affordable Luxury',
  affordableInfo: 'You can be sure that your investment in any of our dress will always be worth it.',
  fastShip: 'Fast Shipping',
  fastShipInfo: 'We have more than 100+ dresses that are shipped within 48 hours.',
  //footer
  about: 'about',
  aboutSta: 'About STACEES',
  shipping: 'Shipping Info',
  searches: 'Top Searches',
  blog: 'STACEES Blog',
  help: 'Help',
  faq: 'F.A.Q.',
  return: 'Return & Refund',
  track: 'Order Tracking',
  tailor: 'Project Tailor Made',
  customer: 'customer care',
  SizeGuide: 'Size Guide',
  colourGuide: 'Color Guide',
  contactUs: 'Contact Us',
  paymentMethod: 'Payment Methods',
  studentDiscount: 'Students & Key Workers Discount',
  ShopByCate: 'Shop By Category',
  promDress: 'Prom Dresses',
  homeComDress: 'Homecoming Dresses',
  weddingDress: 'Wedding Dresses',
  bridesmaidDress: 'Bridesmaid Dresses',
  motherBride: 'Mother of Bride Dresses',
  weddingGuest: 'Wedding Guest Dresses',
  flowerGirlDress: 'Flower Girl Dresses',
  reserved: 'All Rights Reserved.',
  conditions: 'Terms & Conditions',
  privacyPolicy: 'Privacy Policy',
  aboutUs: 'About Us',
  emailAddr: 'Email Address',
  enterEmailAddr: 'Enter email address',
  signTip: 'Signing Up for Emails and Get {symbol} off Any Order.',
  signTip1: 'SIGN UP FOR OUR NEWSLETTERS',
  copyRight: 'Copyright',
  signTit: 'Enter email for newsletter signup',
  subscribInfo: 'Thank you for subscribing! Enjoy shopping at our shop.',
  prefrence: 'Please select a style preference.',
  //list
  home: 'Home',
  ships48: 'Ships in 48hrs',
  sort: 'Sort',
  popular: 'Most Popular',
  recommend: 'Recommend',
  newArrival: 'New Arrival',
  lPrice: 'Low Price',
  hPrice: 'High Price',
  filter: 'Filter',
  filterBy: 'Filter by',
  price: 'Price',
  clearAll: 'Clear All',
  off: 'OFF',
  apply: 'APPLY',
  Color: 'Color',
  Colors: 'Colors',
  '2024 Prom Dresses': `{name} Prom Dresses`,
  'Long Prom Dresses': 'Long Prom Dresses',
  'Ball Gown Prom Dresses': 'Ball Gown Prom Dresses',
  'Sexy Prom Dresses': 'Sexy Prom Dresses',
  'Lace Prom Dresses': 'Lace Prom Dresses',
  'Off the Shoulder Prom Dresses': 'Off the Shoulder Prom Dresses',
  'Two Piece Prom Dresses': 'Two Piece Prom Dresses',
  '2024 Wedding Dresses': `{name} Wedding Dresses`,
  'Beach Wedding Dresses': 'Beach Wedding Dresses',
  'Lace Wedding Dresses': 'Lace Wedding Dresses',
  'Wedding Veils': 'Wedding Veils',
  'Evening Dresses': 'Evening Dresses',
  '2024 Evening Dresses': `{name} Evening Dresses`,
  'Long Evening Dresses': 'Long Evening Dresses',
  'Homecoming Dresses 2024': `Homecoming Dresses {name}`,
  'Cocktail Dresses': 'Cocktail Dresses',
  'Wedding Pumps': 'Wedding Pumps',
  "Women's Shoes": "Women's Shoes",
  "Girls' Shoes": "Girls' Shoes",
  "Dance Shoes": "Dance Shoes",
  nextPage: 'Next<br/>Page',
  searchResFor: 'Search results for',
  sortBy: 'Sort By',
  searchResult: 'Sorry,your search returned no result.',
  products: "Products",
  proNotFound: 'Product Not Found',
  searchFor: 'Search for items',
  'Short Prom Dresses': 'Short Prom Dresses',
  'Sparkly Prom Dresses': 'Sparkly Prom Dresses',
  'Mermaid Prom Dresses': 'Mermaid Prom Dresses',
  'Formal & Evening Dresses': 'Formal & Evening Dresses',
  'A-Line Wedding Dresses': 'A-Line Wedding Dresses',
  'Mermaid Wedding Dresses': 'Mermaid Wedding Dresses',
  'Ball-Gown Wedding Dresses': 'Ball-Gown Wedding Dresses',
  'Plus Size Wedding Dresses': 'Plus Size Wedding Dresses',
  'Reception Dresses': 'Reception Dresses',
  'Colored Wedding Dresses': 'Colored Wedding Dresses',
  'Veils': 'Veils',
  'Long Mother of the Bride Dresses': 'Long Mother of the Bride Dresses',
  'Short Mother of the Bride Dresses': 'Short Mother of the Bride Dresses',
  'Tea Length Mother of the Bride Dresses': 'Tea Length Mother of the Bride Dresses',
  'Plus Size Mother of the Bride Dresses': 'Plus Size Mother of the Bride Dresses',
  'Mother of the Bride Dresses With Jackets & Wraps': 'Mother of the Bride Dresses With Jackets & Wraps',
  'Mother of the Bride Dresses With Sleeves': 'Mother of the Bride Dresses With Sleeves',
  'Long Bridesmaid Dresses': 'Long Bridesmaid Dresses',
  'High-Low Bridesmaid Dresses': 'High-Low Bridesmaid Dresses',
  'Short Bridesmaid Dresses': 'Short Bridesmaid Dresses',
  'Tea-Length Bridesmaid Dresses': 'Tea-Length Bridesmaid Dresses',
  'Mix & Match Bridesmaid Dresses': 'Mix & Match Bridesmaid Dresses',
  'Junior Bridesmaid Dresses': 'Junior Bridesmaid Dresses',
  'Fabric Swatch': 'Fabric Swatch',
  girlsDress: 'flower girls dresses',
  partyShoes: 'party shoes',
  jacketsWraps: 'Jackets & Wraps',
  jelwery: 'wedding party jelwery',
  promShoes: 'prom shoes',
  fashionJelwery: 'fashion jewelry',
  //product
  ships48s: 'Ships in 48 hours',
  reviews: 'Reviews',
  descript: 'DESCRIPTION',
  details: 'DETAILS',
  modelInfo: 'MODEL INFO',
  shipRetrun: 'SHIPPING & RETURN',
  proTime: 'Production Time',
  proTimeInfo: 'The estimated production time is within 2 weeks before items are shipped. Rush Production is available for an additional nonrefundable fee.',
  shipTimeSt: 'Shipping Time—Standard Shipping',
  shipTimeInfo: 'SHIPPING is an additional 3-10 days depending on Location and Shipping Method.Shipping time option will be provided at checkout ',
  seeMore: 'See More',
  authentic: '100% <span>Authentic</span>',
  cusSizem: 'Custom <span>Size</span>',
  freeGarm: 'Free<span>Garment </span><span>Bag</span>',
  highQam: 'High <span>Quality</span>',
  highQa: 'HIGH-QUALITY',
  recently: 'RECENTLY VIEWED',
  easyRet: 'EASY RETURNS',
  proInfo1: 'Designed by STACEES, 100% same as picture.',
  proInfo2: 'Custom size or exclusive design for your dress.',
  proInfo3: 'We offer one free garment bag when you purchase this dress.',
  proInfo4: 'Our dresses are both stylish and comfortable.',
  proInfo5: '14 days return period',
  retPolicy: 'Return Policy',
  retPolicyInfo1: 'Worry-Free Returns are available for standard sizes within 14 days. Custom sizes are final sale.',
  retPolicyInfo2: 'Please make sure they are in their original conditions - unworn, unwashed, unaltered, undamaged, clean, free of lint and hair and with tags intact and attached.',
  allColors: 'All Colors',
  inStockCol: 'In Stock Colors',
  colorChart: 'Color Chart',
  "As Picture": "As Picture",
  size: 'Size',
  pleaseSel: 'Please select',
  customSize: 'Custom Size',
  thisReq: 'This is a required field.',
  sizeGuide: 'Size Guide',
  sizeChart: 'Size Chart',
  toMeasure: 'How to Measure',
  custom: 'Custom',
  inch: 'inch',
  inches: 'Inches',
  centimeters: 'Centimeters',
  orderSwat: 'Order Swatches',
  aviala: 'Availability',
  inStock: 'In Stock',
  proAvailable: 'Rush Production Available',
  additional: 'Additional Requirements',
  additionalInfo: 'Special instructions on the customization or handling (optional)',
  addBag: 'ADD TO BAG',
  orderToday: 'ORDER TODAY, ',
  getIt: 'GET IT',
  tailTime: 'Tailoring Time',
  shipTime: 'Shipping Time',
  processTime: 'Processing Time',
  day: 'Days',
  orderTodyInfo: 'This dress is Made-To-Order. Whether you choose a standard size or custom measurements, our tailors craft each dress to order.',
  completeLook: 'COMPLETE THE LOOK',
  toCart: 'ADD TO CART',
  viewFull: 'View Full Details',
  sizeOrder: 'SIZE ORDERED',
  proLove: "WE THINK YOU'LL LOVE",
  cusReview: 'CUSTOMER REVIEWS',
  bestSal: 'BEST SELLERS',
  shareWith: 'Share With',
  customInfo1: 'Looking for a perfect fit? For an additional ',
  customInfo2: ', our tailors will use your exact measurements to create a made-to-order dress for you.',
  passwd6Char: 'Please enter 6 or more characters.',
  passwdConsist: 'Two input password must be consistent!',
  verfied: 'Verified Buyer',
  allPhoto: 'See All Customer Photos',
  overFit: 'Overall Fit',
  rating: 'Rating',
  'Small': 'Small',
  'Slightly Small': 'Slightly Small',
  'Right': 'Right',
  'Slightly Large': 'Slightly Large',
  'Large': 'Large',
  overallRate: 'Overall Rating',
  customPhoto: 'Customer Photos',
  backPro: 'Back To Product Page',
  basedOn: 'Based on',
  shareLook: 'Share Your Look to Get',
  shareLook1: 'Share Your Look to Get',
  shareDiscount: 'Discount',
  sharePhoto: 'Share Photo/Video',
  allReviews: 'All Reviews',
  writeReview: 'Write a Review',
  fit: 'Fit',
  viewAll: 'View All',
  photos: 'Photos',
  end: 'End',
  share: 'Share',
  qty: 'Qty',
  waistInfo: 'Find the natural waist, This is the smallest part of the waist, Usually about 1 inch (ca. 2.5cm) above belly button, Keep tape slightly loose to allow for breathing room.',
  bustInfo: 'This is NOT your bra size! Wear an unpadded bra (your dress will have a built-in bra), Relax arms at sides, Pull tape across the fullest part of the bust.',
  floorInfo: 'Stand straight with feet together, Measure in bare feet, Begin at the hollow space between the collarbones and pull tape straight down to the floor.',
  hipInfo: 'Find the widest part of the hips, Usually about 7 inch (ca. 18cm) below natural waist, Tape should brush around the bum.',
  extraInfo: 'For floor-length dresses in custom size, choose the extra length option to fit the height of your heels! i.e for 2 inch（5 cm） heels,select "+2 inch"（5 cm）,for flats,please enter "+0".',
  hemInfo: 'Stand up straight with bare feet together.Measure from the center of the collarbone, down to where the front hem of the dress should hit.',
  heightInfo: 'Stand straight with feet together, Measure in bare feet, Begin at the top of the head and pull tape straight down to the floor.',
  armholeInfo: 'In order to take your armscye measurement, you must wrap the measuring tape over the top of your shoulder and around under you armpit.',
  armText1: "This is a measurement around the fullest part of your upper arm.",
  descripts: 'Descriptions',
  processReturn: 'Processing Time & Returns',
  shipAddit: 'Shipping is an additional 1-10 business days depending on location. Shipping time will be provided at checkout.',
  returns: 'Returns',
  requestReturn: 'Request a return within 14 days after receiving.',
  image: 'Image',
  max5Image: '5MB Max Images.',
  confirm: 'Comfirm',
  selectSize: 'Please Select Size.',
  copyUrl: 'CopyURL',
  oftenBought: 'Often Bought With',
  failed: 'FAILED',
  //sizechart
  Bust: 'Bust',
  Waist: 'Waist',
  Hips: 'Hips',
  floor: 'Hollow to Floor',
  extra: 'Extra Length',
  htoHem: 'Hollow to Hem',
  toHem: 'Hollow to Hem(front)',
  bareFoot: 'Height (Bare Foot)',
  armhole: 'Armhole',
  armWidth: 'Arm Width',
  footLen: 'Foot Length',
  toAnkle: 'Hollow to Ankle',
  toTea: 'Hollow to Tea',
  toKnee: 'Hollow to Knee',
  ShoulderWidth: 'Back Shoulder Width',
  sleeveLen: 'Sleeve Length',
  armscye: 'Armscye',
  armCir: 'Arm Circumference',
  child: 'Child',
  //view bag
  viewBag: 'VIEW BAG',
  checkout: 'CHECKOUT',
  shopBag: 'Shopping Bag',
  bag: 'Bag',
  summary: 'Order Summary',
  subtotal: 'Subtotal',
  total: 'Total',
  goToCart: 'GO TO CART',
  proceedCheck: 'PROCEED TO CHECKOUT',
  item: 'item',
  items: 'items',
  secureCheck: 'SECURE CHECKOUT',
  yourDiscount: 'Your Promo or Discount Code:',
  coupon: 'Coupon',
  couponCode: 'Coupon Code',
  useCoupon: 'Use Coupon Code',
  couponEnter: 'Please enter the coupon code.',
  couponSuccess: 'Congratulations! You have gotten a coupon for',
  couponError: 'Sorry, this coupon is invalid.',
  cancel: 'Cancel',
  weAccept: 'We Accept',
  safe: 'Safe & Secure',
  safeInfo: 'Your information is always protected.',
  saveWish: 'Save for Wishlist',
  removeBag: 'Are you sure to remove this item from your bag?',
  saveWishSure: 'Are you sure to save this item to your wishlist?',
  yes: 'Yes',
  bagTit: 'Shopping Bag at STACEES',
  wishListSucc: 'The item has been added into your wishlist.',
  youLove: "YOU'LL LOVE THESE ITEMS!",
  charMax: 'Characters Max',
  bagEmpty: 'Your Bag is Empty',
  continueShopping: 'Continue Shopping',
  myBag: 'My Bag',
  itNotHave: "But it doesn't have to be...",
  youMayAlso: 'You May Also Like',
  remove: 'Remove',
  //checkout
  placeOrder: 'PLACE ORDER',
  pay: 'PAY',
  orderComp: 'ORDER COMPLETE',
  contact: 'Contact Information',
  guestSign: 'Checkout as Guest or',
  signIn: 'SIGN IN',
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  address: 'Address',
  apartment: 'Apartment, suite, etc. (Optional)',
  countryRegion: 'Country Region',
  region: 'State/Province/Region (Optional)',
  regionSel: 'State/Province/Region',
  city: 'City',
  postCode: 'Zip/Post Code',
  gb: 'GB',
  phone: 'Phone',
  creatAcc: 'Creat Account (Optional)',
  save: 'SAVE',
  setDelivAddr: 'Set as default delivery address',
  emailTip: "We'll send your order<br>confirmation here.",
  phoneTip: "In case we need to contact<br> you about your order.",
  passwd: 'Password',
  confirmPasswd: 'Confirm Password',
  addNewAddr: 'ADD A NEW ADDRESS',
  emailRequire: 'Email Address is required.',
  enterEmail: 'Please enter an email address.',
  validEmail: 'Please enter a valid email address.',
  passwdRequire: 'Password is required.',
  minChar: 'min 6 characters',
  confirmPassRequire: 'Confirm Password is required.',
  passNoMatch: 'Password does not match',
  firstNameVerify: 'Your First name must contain a minimum of 2 characters.',
  lastNameVerify: 'Your Last name must contain a minimum of 2 characters.',
  shippAddrVerify: 'Your shipping address should be at least 5 characters long.',
  countryVerify: 'Country is required.',
  cityVerify: 'Your city should be at least 3 characters long.',
  regionVerify: 'State/Province/Region is required.',
  ziPostVerify: 'Zip/Post Code is required.',
  phone6Verify: 'Your phone number must be at least 6digits.',
  passwd6Verify: 'Your Password must be at least 6digits.',
  shipMethod: 'Shipping Methods',
  shipMd: 'Shipping Method',
  standShip: 'Standard Shipping',
  free: 'Free',
  estDelive: 'Estimated Delivery',
  expShip: 'Expedited Shipping',
  duty: 'Duty Insurance',
  shipInsure: 'Shipping Insurance',
  dutyTip: "If you buy Duty & Tax Insurance and your package is charged additional duties and taxes upon delivery, please contact our Customer Service and we will compensate the duty and tax you have paid.",
  shipTip: "Shipping insurance offers premium protection and safety for your valuable items during international shipping. We'll reship your package immediately at no extra charge if it's reported lost or damaged.",
  editBag: 'Edit Bag',
  shipFee: 'Shipping Fee',
  picture: "Picture",
  edit: 'Edit',
  delete: 'Delete',
  delAddress: 'Are you sure you want to delete this address?',
  saveShipp: 'Please save your shipping address.',
  sizeDetail: 'Size Details',
  addrBook: 'Address Book',
  default1: 'Default',
  selected: 'Selected',
  credit: 'Credit or Debit Card',
  creditNote: 'Note: For security purposes, we will not save any of your credit card data.',
  paymentTrouble: 'Have Trouble in <br> Payment?',
  paymentText: 'You can contact us for payment assistance via:',
  openWait: 'Opening, please wait...',
  failedPurchase: 'Failed Purchase! Please complete the payment again.',
  eml: 'E-mail',
  //login
  signRegister: 'SIGN IN/REGISTER',
  register: 'REGISTER',
  newTo: 'NEW TO STACEES',
  join: 'JOIN',
  now: 'NOW',
  forgetPasswd: 'Forgot password',
  forgottenPasswdHead: 'Forgotten password',
  forgottenPasswd: 'Forgotten password',
  or: 'or',
  signFackbook: 'Sign in with Facebook',
  signGoogle: 'Sign in with Google',
  iAccept: 'I Accept',
  signFor: 'Sign up for <span>{symbol}</span> coupon in your account.',
  accountPrivacy: 'By creating an account, you agree to the <a class="udline" href="{hrefPrivarcy}" rel="external nofollow">Privacy Policy</a> of Stacees.com',
  orContinue: 'or continue with',
  bySignIn: 'By signing in, you agree to our <a href="{hrefPrivarcy}" rel="external nofollow">privacy policy</a> <br/>and  <a href="{hrefTerms}" rel="external nofollow">terms of service</a>.',
  clickJoinNow: `By clicking 'Join now', you agree to STACEES's <a href="{hrefTerms}" rel="external nofollow">Terms & Conditions</a> and <a href="{hrefPrivarcy}" rel="external nofollow">Privacy Policy</a> .`,
  //forgot
  veriCode: 'Verification Code',
  codeReq: 'Code is required.',
  codeVerify: 'Please enter Code',
  codeError: 'Code is error',
  send: 'SEND',
  forgotTxt1: "How to reset your password ?",
  forgotTxt2: "Please type your registration email address on the box above and you’ll receive an email with instructions on how to change your password.",
  forgotTxt3: "Type your email address",
  forgotTxt4: "Check your email",
  forgotTxt5: "Click on the link to reset password",
  forgotTxt6: "Log in with your new password",
  forgotTxt7: "A Link To Reset Your Password Has Been Sent To",
  forgotTxt8: "Emails may experience a few minute delay.",
  forgotTxt9: "If you have not received an email, please check your junk and spam folders.",
  forgotTxt10: "If you still don't receive the email after requesting a password reset, wait 24 hours and ",
  forgotTxt11: "try again",
  forgotTxt12: 'Enter your email address and we will send you a link to reset your password.For security sign in links are valid for 30 minutes.',
  resetPass: 'Reset Password',
  createNewPass: 'CREATE A NEW PASSWORD',
  newPassInfo: 'Thanks for coming back-please enter your new password details below',
  newPassTxt: "Your password has been changed. Sign in again and you're good to go",
  newPassRequire: 'New Password is required.',
  passMin6: 'Password length should be minimum 6 characters.',
  oldPassRequire: 'Old Password is required.',
  passAgain: 'Please enter your password again.',
  submit: 'SUBMIT',
  back: 'Back',
  //member
  welcomeTo: 'WELCOME TO',
  yourAcct: 'Your account',
  hi: 'Hi',
  myOrders: 'My Orders',
  myCoupons: 'My Coupons',
  myReviews: 'My Reviews',
  myWishlist: 'My Wishlist',
  myTickets: 'My Tickets',
  myProfile: 'My Profile',
  changePassword: 'Change Password',
  signOut: 'Sign Out',
  orderEmpty: 'YOUR ORDER IS EMPTY',
  orderNo: 'Order No',
  date: 'Date',
  status: 'Status',
  open: 'Open',
  pending: 'Pending',
  closed: 'Closed',
  processing: 'Processing',
  canceled: 'Canceled',
  shipped: 'Shipped',
  completed: 'Completed',
  awaitPaym: 'Awaiting Payment',
  viewDetail: 'View Details',
  reorder: 'RE-ORDER',
  ticketServ: 'Tickets Service',
  payNow: 'PAY-NOW',
  cancelOrder: 'Are you sure you want to cancel the order',
  shipAddr: 'Shipping Address',
  billAddr: 'Billing Address',
  delivery: ' DELIVERY OPTIONS',
  paymentMeth: 'Payment Method',
  subTot: 'Sub-total',
  grandTotal: 'Grand Total',
  wishlistEmpty: 'YOUR WISHLIST IS EMPTY',
  dateOfBirth: 'DATE OF BIRTH',
  gender: 'Gender',
  ms: 'Ms',
  mr: 'Mr',
  mPrivacy: 'Privacy',
  saveChanges: 'SAVE CHANGES',
  firNameRequire: 'First Name is required.',
  lastNameRequire: 'Last Name is required.',
  optional: 'Optional',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  changed: 'CHANGED',
  enterPass: 'Please enter your password.',
  ticketId: 'Ticket ID',
  type: 'Type',
  lastUpdate: 'Last Updated',
  changeSucc: 'changed successfully!',
  saveSucc: 'Saved successfully!',
  brith: 'Choose Brithday',
  //ticket
  creatTicket: 'Create a Ticket',
  backOrder: 'Back to Order',
  selectTopic: 'Select a topic',
  backTicket: 'Back to Tickets List',
  problemSoved: 'Your problem has been solved. This ticket has been closed',
  addAPhoto: 'Add a Photo',
  photoTip: 'Maximum of <span class="cr-3c">3</span> photos.Only provide <span class="cr-3c">JPEG, GIF, or PNG </span>files.Individual photo size cannot exceed <span class="cr-3c">8MB.</span>',
  conversReq: 'Conversation is required.',
  photoTipReq: 'Maximum of 3 photos.Only provide JPEG, GIF, or PNG files.Individual photo size cannot exceed 8MB',
  ticketReceive: 'Your ticket has been received,we will reply you soon.',
  produ: 'Product',
  orderTxt1: 'Orders canceled within 12 hours of payment confirmation will be eligible for a partial refund consisting of full shipping cost and 90% of product purchase price.',
  orderTxt2: 'Orders canceled within 12-24 hours of payment confirmation will be eligible for a partial refund consisting of full shipping cost and 80% of product purchase price.',
  orderTxt3: 'Orders canceled within 24-48 hours of payment confirmation will be eligible for a partial refund consisting of full shipping cost and 50% of product purchase price.',
  orderTxt4: 'Orders canceled beyond 48 hours after payment confirmation will be eligible for a partial refund of full shipping cost only.',
  orderTxt5: 'Once your order has been shipped, it can no longer be canceled.',
  phoneNum: 'Phone Number',
  message: 'Message',
  messageTip: 'Please write your detailed message in 5-1000 characters …',
  messageReq: 'Message is required.',
  photoCode: 'Please enter the verification code on the picture: *',
  ticketBtn: 'Submit Ticket',
  topicReq: 'Topic is required.',
  yourNameRequ: 'Your Name is required.',
  codeRequ: 'Verification Code is required.',
  verCodeErr: 'Verification Code is error',
  'Dispute': 'Dispute',
  'Color deviation': "Color deviation",
  "Size issues": "Size issues",
  "Late for delivery": "Late for delivery",
  "Differ from the pictures": "Differ from the pictures",
  "Quality deviation": "Quality deviation",
  "Customs&Tax": "Customs&Tax",
  "Shipping problem": "Shipping problem",
  "Other problems": "Other problems",
  'Products cancellation': 'Products cancellation',
  'Event was canceled': 'Event was canceled',
  'Worry about the quality': 'Worry about the quality',
  'Time is not enough': 'Time is not enough',
  'Ordered wrong items': 'Ordered wrong items',
  'Found better service from other shops': 'Found better service from other shops',
  'Order modification': 'Order modification',
  'Style': 'Style',
  'Delivery date': 'Delivery date',
  'Size': 'Size',
  'Others': 'Others',
  'Address': 'Address',
  addConver: 'Add a Conversation',
  proInfo: 'Prouduct Information',
  loadFailed: 'Loading failed, please refresh the page',
  //review
  viewReview: 'View Review',
  yourName: 'Your Name',
  reviewTit: 'Title of Your Review',
  reviewTitTip: 'Tell us what you think',
  yourReview: 'Your Review',
  yourReviewReq: 'Your Review is required.',
  commentsTip1: 'TIPS:\nAre you satisfied with your purchase? \nYou can describe the product from quality, style, fitness, material etc.',
  addPhoto: 'Add Photos(Optional)',
  toUpload: 'Click here to upload',
  nameReq: 'Name is required.',
  reviewTitReq: 'Title of your review is required.',
  reviewReq: 'Review is required.',
  imageMax5: '5 images max, 5MB max per image.',
  revHeadlineReq: 'Review Headline is required.',
  ProCommentsReq: 'Product Comments is required.',
  ReviewsEmpty: 'REVIEWS IS EMPTY',
  //coupon
  allCoupons: 'All Coupons',
  availableCoupons: 'Available Coupons',
  expiredCoupons: 'Expired Coupons',
  expiredeEmpty: 'Your expired coupons are empty.',
  availableEmpty: 'Your available coupons are empty.',
  newRegistDiscount: 'New Registered Discount',
  orderOver: 'order over',
  expires: 'Expires',
  copyAndUse: 'Copy and Use in Check Out Page',
  code: 'CODE',
  copy: 'COPY',
  successCopy: 'Successful copy!',
  //Order tracking
  trackYourOrder: 'Tracking Your Order',
  sigleOrder: 'Look up a single order',
  orderInfo: 'Please enter your Order Number and Email Address to see the details for your order.',
  orderQues: 'Where can I find this?',
  orderTrackInfo: 'Our order number is included in the email you received when you placed your order. If you no longer have this email, please?<a class="cr-bd" href="{hrefContact}">contact us</a>.',
  orderHistory: 'View order history',
  signAccount: 'Sign in to your account to view all your orders.',
  orderRequire: 'Order No is required.',
  trackText: 'If you have an account already, sign in to check your order.',
  trackingOrder: 'Tracking Order',
  orderTrack: 'Order Track',
  trackOrderInfo1: 'If you want to check more information of your order, you can register with the e-mail address',
  trackOrderInfo2: 'Any more questions, you can <a href="{url}">Create a Ticket</a> in your account.',
  trackShip: 'Track Shipment',
  placed: 'Placed',
  material: 'Material Preparation',
  inProduct: 'In production',
  quality: 'Quality Inspection',
  parcel: 'parcel',
  shippedBy: 'Shipped by',
  trackNumb: 'Tracking number',
  trackinfo: 'This order has been shipped by carrier, tracking information is expected to be updated within 1-3 days.',
  paid: 'Paid',
  shipment: 'Shipment',
  take: 'Take',
  dayArrive: 'days to arrive',
  complete: 'Complete',
  //gallery
  note: 'Note:',
  sahreNote: '1 video max, 50MB max per video,<br/>or 5 images max, 5MB max per image.',
  sahreNoteReq: '1 video max, 50MB max per video,or 5 images max, 5MB max per image.',
  next: 'next',
  name: 'Name',
  revHeadline: 'Review Headline',
  ProComments: 'Product Comments',
  commentsTip: '(Are you satisfied with your purchase? You can describe the product from quality, style, fitness, material etc.)',
  shareSucTxt1: 'THANK YOU LOVELY!',
  shareSucTxt2: 'We will double check the photo just to make sure all is well!',
  shareSucTxt3: 'Your photo will be posted after approval. Thank you love!',
  continueUpload: 'CONTINUE UPLOADING',
  viewGall: 'VIEW GALLERY',
  uploadVideoReq: 'please only provide MP4 video files, or JPG / PNG / JPEG / BMP image files, do not upload pictures and videos at the same time.',
  shareStory: 'Share Your Story',
  all: 'All',
  emptyRefresh: 'Empty? please refresh the page',
  shopLook: 'SHOP THE LOOK',
  photosReq: 'Photos is required.',
  //about
  about1: 'WELCOME TO STACEES!',
  about2: 'Thank you for coming to STACEES, first, you will find that thousands of products are offered at incredible price here.',
  about3: 'At STACEES, we have our own wedding dress factory with a professional design team and a complete wedding dress production and manufacturing process.',
  about4: 'Established in 2012, STACEES is an online retailer of wedding dresses, special event dresses, wedding party dresses and accessories. Customers can browse a large selection online and choose their favorite dresses with great satisfaction. We are committed to providing our customers with high quality products and high-quality services.',
  about5: "We provide our customers with a wide range of high quality wedding dresses. Each of our wedding dresses bridesmaid dresses, mom dresses, evening dresses and prom dresses will be presented to our customers with unique style, tailor-made design and attentive service.",
  about7: 'We have a blue-ribbon dresses manufacturers in our country and we are strict with course of production, paticular in minutia, even if a little problem occured on the zipper, we will return and work over again, however, it never happened.',
  about8: 'Service',
  about9: 'Customer service representatives are 7*24 hours online who are professional and specialized in solving any questions, do not hesitate to contact us at anytime you want.',
  about10: 'Brand',
  about11: 'STACEES is a leading brand in making custom dresses all over the world, we offer the latest and most popular dresses and provide extremely kinds of service for all customers who choose us. Each dress is custom made, the dress will closely fit on you if your measurenebrs are all right. We are always dedicated in producing the most beautiful dresses with highly quality, so your satisfaction is the greatest encouragement to us.',
  about12: 'Management',
  about13: "We have an outstanding management technician group, they will safeguard the whole process in STACEES is in working order and also each workline will be outfit with an management official eventually. In order to ensure the high quality of our products and services, we successfully bring in international topmost manufacturing technology.",
  about14: 'For the satisfaction of our customers, we provide an urgent processing service for the time-lacking buyers. To make sure every of your order arrive in perfect condition, we work with many official express service, such as DHL, UPS, Aramex, EMS and so on. We will select the best service for you according to your location and requirement. Tenet of our company: quality assurance and survival for good reputation to server our customers wholeheartedly.',
  about15: 'High Quality / Affordable Prices',
  about16: 'The dresses are produced with high quality by top tailor and we are glad that is why more and more customers choose us. Every girl deserves the service and quality at STACEES.In order to offer a more lower and competitive price, we won’t often update it unless our company makes some adjustments on some of the best seller dresses. Everyone can have a coupon code which can save $ 5 after you successfully registered on our site.',
  about17: 'Should you have any further questions, please contact us.<br />By email using the mail address in <a class="undline" href="{hrefContact}">Contact Us</a> page.',
  //shipping info
  shipPolic:'Shipping Policies',
  shipInfor:'Shipping Information',
  shipInfo1: 'Shipping Regions, Shipping Methods, Shipping Time and Shipping Costs and Additional Information about the shipping process of STACEES',
  shipInfo2: 'Our products can be delivered all over the world. We ship our products using the following carriers: (UPS, DHL, TNT, Fedex, etc.). The shipment time varies with the shipment distance. And the efficiency of local carriers also affects the delivery time. It takes about 2-15 business days for the delivery from our factories to your destination. The exact shipping time depends on the standards of the carrier (UPS, DHL, TNT, Fedex, etc.) and the shipping method you choose. Usually, the time and cost of shipping to different areas are as follows.',
  shipInfo3: 'Africa',
  shipInfo4: 'Island Countries on the Ocean',
  shipInfo5: 'United Kingdom',
  shipInfo7: 'Other countries in Europe',
  shipInfo8: 'The USA and Canada',
  shipInfo9: 'Other Countries in North America',
  shipInfo10: 'Other Countries in South America',
  shipInfo11: 'Australia',
  shipInfo12: 'Other Countries in Oceania',
  shipInfo14: 'The above days are all business days, during the major holidays, the number of airport freight will increase and the transportation will be delayed. If you need your order urgently, please contact us in advance and inform us of the date you need. We will arrange it for you as soon as possible.',
  shipInfo15: 'THE SHIPPING FEE: Our shipping costs depend on the total weight of your order and the country the dresses will be shipped to.',
  shipInfo16: 'Before your order is shipped, STACEES site must process your orders and pass strict quality control tests on all your projects. Therefore, the total delivery time is different from the separate shipping time',
  shipInfo17: 'Production And Delivery Time',
  shipInfo18: 'Total Delivery Time = Processing Time + Shipping Time',
  shipInfo19: 'If your order contains multiple items, they will be shipped together.',
  shipInfo20: 'In general, depending on the complexity of the dresses.Processing time differs from category to category. Please refer to the table below for more precise processing to better estimate when you will receive your order:',
  shipInfo23: 'Specific processing time depends on the items. The delivery time of accessories, swatches and ome itemsdepends on our inventory and they can be shipped within 24 hours when we have sufficient stock.',
  shipInfo24: ' We will process your order within 24 hours and inform our tailors to start making dresses for you. Therefore, if you want to cancel the order, please contact us within 24 hours, and we will charge for cancellation of the order for more than 24 hours, because the fabric used in the dress has been cut. For detailed cancellation policy, please refer to our <a href="{hrefOrderCan}">Order Cancellation</a> page.',
  shipInfo26: "We also offer an expedited service for customers who need a dress urgently you can the service of rush order for some styles. You can choose the Arrival Date on the option on our product page. When you choose a very early date, the system will automatically calculate the extra cost of the rush production for your dress and there will be a visible sign on the price. However, if you receive the dress at a very late date, we will give you a 5% discount. In order to have enough time to make you a better-fitting and more sophisticated dress, you'd better place your order early.",
  shipInfo27: 'Anymore, if you do not receive your order within the time frame you choose, we will offer you limited reimbursement.',
  shipRegion: 'Shipping Regions',
  shipCost: 'Shipping Costs',
  from: 'From',
  shipInfo28: 'Countries in Europe',
  rushOrder: 'Rush Order',
  category: 'Category',
  specialOccasion: 'Special Occasion Dresses',
  motherTheBride: 'Mother of the Bride Dresses',
  accessories: 'Accessories',
  shoes: 'Shoes',
  swatch: 'Swatch',
  shipp: 'Shipping',
  //faq
  askQuest: 'Frequently Asked Questions',
  livechat: 'Livechat',
  payment: "Payment",
  faq46: "We're Here To Help!",
  faq47: "Get quick help with order, shipping, returns, or refunds in-quiries here. For further support, please feel free to contact customer service.",
  faq1: "Can I have more discount on multiple or bulk orders?",
  faq2: "We have tried the best to make most dresses in reasonable and affordable price. To make every girl will have their flattering styles on their event, we are glad to do a deal on the bulk orders according to the amount and quantity. For more discount details, please contact our online assistant or email us.",
  faq3: "Can I amend the order?",
  faq4: "To make sure every order will work for you and come as expected, we will always send you the confirmation email to double confirm the order, you are still allowed to tell us your requirements of the order in certain time. In order to make the order arrive by schedule, orders will be processed after confirmation or no query submitted in 12 hours from the confirmation email. Please understand that orders once been processed are not easily or possible to amend due to schedule, material and tailoring cost .",
  faq5: "How do I check the order status?",
  faq6: 'Once order placed, you can check the order status any time in <a href="javascript:void(0)" class="myOrder">My Account</a>, you will learn in which step the order is there. You may also email us or contact us online for further details.',
  faq7: "I want to pay but why the payment is declined?",
  faq8: "We believe that every customer will enjoy the safe and convenient shopping here, as we provide the most popular payment option, Paypal, Credit Card/Debit Card. But unluckily if your payment is declined, don’t worry, we have got you here. That is because of the restriction from the bank system, there are many factors take effect, such as cards used another place, frequently used recently, etc. You will just need to use another card or make a call to ask your bank to lift that restriction and try again.",
  faq9: "How do I use the coupon?",
  faq10: "Please fill in the coupon in coupon code box which displayed in the Checkout page, and then apply it. No matter your registered coupons or negotiated coupons from sales assistant can be used there.",
  faq11: " Is it safe to make the payment here?",
  faq12: "The security of the information our customers submit is one of our highest priorities. We are verified by International Bank Validation System and Paypal, supervised under their terms and policy. You will have the most safe shopping experience here.",
  faq13: "What kind of cards can be used to pay?",
  faq14: "Most credit cards and debits cards are easily to pay here. They can be visa cards, master cards, etc.",
  faq15: "Can I alter the shipping address?",
  faq16: "You are allowed to alter the shipping address before the order goes in delivery stage. There may be additional shipping cost and custom duties depends on destination and their local policy, you may check with our online assistant and local department also.",
  faq17: "How long is the shipping time?",
  faq18: "Every order here will be promised with safe and door to door service. It will get to your house in about 5 days after dispatching. Please understand that the estimate arrival date can not be as reliable as you expect when there is holiday, unexpected horrible weather or other things may affect the delivery service.",
  faq19: "What if I miss the delivery?",
  faq20: "After the first arrival tempt failed, you will be able to find your local express service contacts in our delivery failed email, and ask them to arrange another delivery in the other day which seems convenient to you. Please noted that if you are out of contact or out of reach during several tempt delivery, there may be additional charge for re-delivery. For parcel returned to us or destroyed by express service due to customers long term no response can not be concluded as our fault.",
  faq21: "Return&Refund",
  faq22: "Can i return the dress when i receive it?",
  faq23: "You can return the dress and get refund if there is anything wrong with the dress you received.",
  faq24: "I returned the dress,when can i receive the refund?",
  faq25: "We will arrange the refund upon receiving the dress you return.Generally,you will have the refund in about 2-5 days.",
  faq26: "Where should i return my dress?",
  faq27: "You need to contact our service team to get a return form before you return the items.Please don’t return it to the address on the parcel.",
  faq28: `Can I have multiple color and size ordered for the same style?`,
  faq29: `We can provide the dresses and gowns in every size and color listed, even personal size. Do not worry about the stock, our salon tailors are experienced to provide large quantity for your big party.`,
  faq30: "Can we have the dress slightly altered?",
  faq31: "If you want the dress with personality, we can help to alter the dress a bit for you, please contact us online or email us your ideas, we will try the best to meet your request.",
  faq32: "How can you make sure the returned-order exactly the same with previous ones?",
  faq33: `Due to many special orders are hand made, slight deviation of colors and details are inevitable between your returned orders, but there is way to fix it. You are required to provide pictures of dresses you received, so that orders can be match easily.`,
  faq34: `How can I make sure the size and color work perfectly for me?`,
  faq35: `We understand that every dress can be very important to help you stand out. To have a perfectly fitted dress, please select your size exactly according to the size chart or tell us your exact size when you order. Color can be slightly different because of your monitor, photography light and surrounding, we suggest you get the <a class="fabric" href="javascript:void(0)">fabric samples</a> on the site to help you decide.`,
  faq36: "Can I have a dress which is not on the site?",
  faq37: "If you have got a design in mind or see some fantastic dresses other resources, please let us know, just show us the pictures, we will check with our skilled tailors and provide you quotation within 12 hours.",
  faq38: "How do I reset my password?",
  faq39: "Simply follow the Forgotten Password instructions. For security reasons, we cannot send you your password via email. You will get reset account link by email, and follow that link to reset the password. If email not received, please contact us to have our technology department manually reset that for you.",
  faq40: "Do I have to be a member to buy here?",
  faq41: `You can also order here as guest. But to have account is easier to track your order, save frequent addresses and submit tickets. You can easily create your account <a href="javascript:void(0)" class="myOrder">here</a> in minute.`,
  faq42: "How do I change the account email address?",
  faq43: "To make sure the account in secure condition, you are not allowed to amend the registered email address, but you can tell us the email address you want to use, we will record that and update the order status with that address.",
  faq44: "If have any questions about my order,what should i do?",
  faq45: "In your account ,you can check your order status in “My order”.If you have any questions about your order upon receiving it,you can send us a ticket.Our service team will reply you within 24 hours.",
  //Return & Refund
  refundTit: 'Refund, Returns And Exchanges',
  retRefund: 'RETURNS & REFUND MADE EASY',
  retRefundCont1: "Thank you for shopping at STACEES.",
  retRefundCont2: "If you are not fully pleased with your order, we are here to assist. We will provide a full refund for your purchase, including shipping fees for all damaged, defective, or wrongfully shipped items. If you are not happy with your purchase, don't hesitate to contact us as soon as possible to start processing your return and refund.",
  retRefundCont3: "Conditions for Return",
  retRefundCont4: "You can only return goods within 14 days from the day you receive them. We advise our customers to examine the contents of their delivery as soon as it arrives. Once you are sure the items are damaged or wrongfully shipped, please initiate the return/refund process as soon as you can.",
  retRefundCont5: "Please kindly contact our customer service department and ask to return your order. Please do not return the package directly to the address on our website or on the package, which are not our return address.",
  retRefundCont6: "To be eligible for return/refund, the item must be in its original state. We will not accept the return of used, worn, washed, altered, or damaged goods. Please ensure that you leave the original tags on the items if you wish to return them.",
  retRefundCont7: "AII our items are made to order. This means that we do not stock extra items in our store. Once you place your order, it will be made specifically for you. Therefore, we will not accept returns of goods that are as per your order. Our return policy only accommodates damage or wrongful shipment of items.",
  retRefundCont8: "When you ship your return to us, please ensure that you keep your shipping receipt and tracking information. We are not liable for returned goods that are wrongfully shipped, damaged, or lost during shipping.",
  retRefundCont9: "Once you receive an item that you think is defective, damaged, or wrongfully shipped, please take images of the original package. This will help us fast-track the processing of your return, partial refund, or full refund.",
  retRefundCont10: "Once we receive your returned goods, we will inspect your order and notify you that we have received them. Once your return is processed and approved, we will initiate the refund process via the original payment method. Depending on your card issuer's policies, you can expect to receive your credit within a few days.",
  retRefundCont11: "For All Standard/Custom Size Orders",
  retRefundCont12: "For All Style/Size Selection Orders",
  retRefundCont13: "Adjustments And Reimbursements",
  retRefundCont14: "Fabric Swatches, Personalize Items, Accessories And Rush Orders.",
  retRefundCont15: "For All Final Sale Items",
  retRefundCont16: "Defective, Damaged Or Mis-Shipped Items",
  retRefundCont17: "Sizing And Fitting Issues",
  retRefundCont18: "Size Deviation",
  retRefundCont19: "Color Mismatch",
  retRefundCont20: "Hand-Made Issues",
  retRefundCont21: "Exchange Policy",
  retRefundCont22: "How to Process Your Return, Refund, or Reimbursement",
  retRefundCont23: "Cancellation Policy",
  retRefundCont24: "STACEES will process a full refund, plus a shipping fee for all damaged, defective, or wrongfully shipped goods. If you are unsatisfied with your order, please take images of the original order so that we can process your refund.",
  retRefundCont25: "If you change your mind about the order, please process the return request within 14 days of receiving the order. Note that these are calendar days, so weekends are also considered. Therefore, it is in your best interest to contact our customer service department as soon as possible to process your return.",
  retRefundCont26: "All undamaged returns should be shipped back in their original condition. We do not accept the return of worn, washed, altered, or damaged items. Also, ensure that all returned items are shipped back with their original tags. We may not issue a return for goods that do not meet these conditions or comply with our return policy.",
  retRefundCont27: "Once you have submitted your return request and we have acknowledged receipt, it will take up to 7 business days to process your return, exchange, or refund. Please note that the time it takes to receive your credit depends on the policies of your card issuer or bank. Usually, it takes between 7 to 14 working days from most card issuers to process a refund.",
  retRefundCont28: "All custom fit orders are tailor-made to your specifications. This essentially means that once the order is complete, it will be for you and you only. We can't resell a custom-fit order to another customer. Therefore, we will not process returns or refunds for custom-fit orders unless there is a quality issue. Before you place your order, please ensure that you go over and confirm details about the color, style, and measurements within 24 hours of finalizing your order.",
  retRefundCont29: "If there is a quality issue, please take images of the original shipment and contact our customer services to request a return. We will notify you once your request has gone through, and a return or refund can be processed within seven days of receiving and inspecting your order.",
  retRefundCont30: `Please understand that all our dresses are made-to-order, which means the dresses you order are special for you. We put our heart into every dress,the dresses can't be restocked or resold if returned.For this reason, we can't accept "buy-many-keep-one" order. In order to reduce environmental waste and waste of resources, we do not suggest “buy-many-keep-one” order. Once your order is confirmed, we will think that you need all the items in your order and strictly follow our return and refund policy.`,
  retRefundCont31: `If you wish to keep your order and make your adjustments, we can offer you a reimbursement. We understand that sometimes, some minor adjustments might need to be made to your order to get the perfect fit. Hence, we offer limited alterations reimbursement for all dresses. This includes both standard sizes and tailor-made dresses. All you need to do is forward us a copy of the tailor's receipt and your order number. Here are our reimbursement limits for custom fit order dresses in USD:`,
  retRefundCont32: `For dresses priced $99 or less - Reimbursement limit $35`,
  retRefundCont33: `For dressed priced $100 - $199 - Reimbursement limit $50`,
  retRefundCont34: `For all dresses above $200 - Reimbursement limit $75`,
  retRefundCont35: "After a final sale, we cannot process returns, refunds, or exchanges for all fabric swatches, personalized items, veils, or accessories.",
  retRefundCont36: "We will not refund the rush fees on rush orders unless the order is defective. If you receive a , defective order, you can contact us, and we will inspect and process the rush fee as soon as possible.",
  retRefundCont37: "We will not be able to process any returns or refunds for Final Sale items.",
  retRefundCont38: `You are eligible to receive a full refund if you receive defective, damaged, or wrongfully shipped items. However, if you believe the items were damaged during shipment, you must provide "Proof of Damage" documentation from the delivery company. This should be included together with images or items when you submit your return request.`,
  retRefundCont39: "To allow for slight modifications, our tailors reserve additional fabric in the seams of all dresses. We understand that sometimes a finished gown might vary by approximately an inch in either direction of the specified measurements. This will allow you to make alterations so the dress can fit you perfectly.",
  retRefundCont40: "Suppose your dress size deviates from your order specifications by more than an inch. In that case, you can find a local tailor and have them make adjustments. For such In this case, we will reimburse you up to 40% of the product purchase price (excluding shipping cost).Should you choose this option, please remember to request a receipt from your tailor. A copy of the receipt must be provided to request reimbursement. ",
  retRefundCont41: "Concerning color mismatch, please note that there might be a slight color deviation due to your computer screen or mobile device settings. However, if you are convinced that the color is not what you ordered, please contact Customer Service to see if a return or refund is possible.",
  retRefundCont42: "Most of our dresses are handmade by our experienced tailors so there might be some issues during the customization, we will not treat it as quality issues and no return and exchange.",
  retRefundCont43: "We have some tips for you to deal with the issues.",
  retRefundCont44: "1. Threads. Use scissors to cut it, please be careful.",
  retRefundCont45: "2. Creases. Iron the clothes. Please iron the opposite side (inner side) of the clothes with beads, embroidery or other embellishments",
  retRefundCont46: "3. Slight Mark. This is mainly caused by tailors when using a water erasable pen for tailoring. It's easy to clean by gently rubbing with water.",
  retRefundCont47: "Do you wish to exchange your dress for a different size or item? Unfortunately, we currently don't offer an exchange service for any products at this time. All our dresses are made to order. Therefore we will not have any extra dresses for exchanging. If you want a different item, please return the order and start a new order from scratch.",
  retRefundCont48: "1. Create a ticket in your order list within 14 days upon receiving your order. Please include the reason for return and photographs for verification. Please follow the picture:",
  retRefundCont49: "2. Once we have sent permission to return the goods, we will also provide a returning address via Tickets. All you need to do is print out the address and attach/put it inside the package with the goods you wish to return.",
  retRefundCont50: "3. Once you have successfully shipped the package, please notify our customer service representative that your shipment is on its way. You must also provide them with the shipment's tracking number provided by your Local Post Office.",
  retRefundCont51: "4. Upon receiving your returned order, we will notify you via email. We will then inspect your item and a refund/return will be processed within 3 to 5 business days. If your return is not in line with our returns and refunds policy, we may not be able to process a refund. The item must be in satisfactory condition. Once your refund is accepted, you can expect your credit within 7-15 business days via the original payment method. Please note that the processing period depends on your card issuer or bank.",
  retRefundCont52: "Unfortunately, due to current situations, your refund will require an additional 3-5 days to process. Thank you for your patience and understanding.",
  retRefundCont53: "Conditions",
  retRefundCont54: "* The item must be unworn, unwashed, unaltered, undamaged and with the original tags attached. We cannot process the return of any faulty item.",
  retRefundCont55: "* We reserve the right to refuse the return if received in an unacceptable condition or without our prior knowledge.",
  retRefundCont56: "* We are not liable for return item(s) that are lost or damaged, please keep the receipt with tracking information.",
  retRefundCont57: `All our items are made-to-order, including standard-size items. If you wish to cancel an order for any reason, please refer to our cancelation policy below: <a class="bold" href="{hrefOrderCan}">Order-Cancellation Policy</a>`,
  //project tailor made
  tailor1: '<p>How many times have you come across that perfect dress on social media but had no idea where to buy it? Or </p><p>perhaps, you found your dream frock, to then discover the seller does not stock your size or deliver to your country.</p>',
  tailor2: '<label>We know how difficult it can be to </label><span>find the ideal outfit for a special occasion. </span>',
  tailor3: '<p>And, regardless of whether you’re shopping for a birthday, prom, graduation, or any other memorable </p><p>day, only the best dress will do. This is why we’ve created our innovative tailor-made dress service so  </p><p>that you can have a dress that is as special as the occasion. </p>',
  tailor4: 'How does our tailor-made dress service work?',
  tailor5: '<p>With our intelligent system, you can upload a photo of any</p><p>dress you find online. So, the next time you’re scrolling through</p><p>Pinterest and find a beautiful gown, save the image and upload</p><p>it via the picture upload function. We can recreate any dress</p><p>you see online, even if it’s from a competitor’s site.</p>',
  tailor6: '<label>The benefits of choosing a </label><span>tailor-made dress</span>',
  tailor7: 'So why should you choose a tailor-made dress over a mass-produced garment?',
  tailor8: '<p>Firstly, many high street gowns give an unflattering fit because they are not </p><p>created for your particular body type or height. However, a tailor-made dress </p><p>promises the perfect fit as the garment is designed and stitched to your exact </p><p>measurements.</p>',
  tailor9: '<p>Secondly, it’s no secret that many mass-produced dresses use low-quality fabric. These materials can </p><p>feel itchy and irritating and do not last long. Therefore, you’ll likely only wear the frock once. However, we </p><p>produce all our tailor-made dresses using the highest quality fabrics for maximum durability and comfort.</p>',
  tailor10: 'Along with uploading the photo, you’ll need to provide us with a few pieces of information to receive an accurate quote for the dress creation.',
  tailor11: 'Your body shape/figure. You can tell us your body type, e.g., pear, apple, hourglass, or general body figure, such as slim, curvy, or athletic.',
  tailor12: 'The occasion you plan to wear the dress.',
  tailor13: 'Any other requirements or personalized wishes you have to make the dress extra special and unique.',
  tailor14: 'submit a Tailor Made',
  writeTailor: 'Write a Tailor Made',
  dressPic: 'Dress Picture',
  content: 'Content',
  contReq: 'Content is required.',
  tailor15: 'Once you upload the photo and provide the above details, our service rep will evaluate the <p>information and get in touch with a quote.</p>',
  tailor16: 'Max 5 images,5MB per one. Format: jpeg/jpg/png/bmp Clear photos are much appreciated!',
  tips: 'Tips',
  pleaseLogin: 'Please login',
  success: 'Success!',
  //size chart
  specOccasion: 'Special Occasion',
  juniorBrid: 'Junior Bridesmaid',
  jackets: 'Jackets/Wraps',
  sizeTxt1: 'Below are size charts for different products. Find the size that’s right for you',
  sizeTxt2: "It is important to be familiar with your own size when choosing a dress size. Only by knowing your personal measurements you can have a dress that fits you best. This is because each designer may use only their own rules to define dress sizes. For example, what might be a size 6 in your everyday garment, and could be a size 10 in the same size in our dresses or in another designer's dresses.",
  sizeTxt3: 'Please do check Bust, Waist and Hips when choosing size.',
  sizeTxt28: 'To know your measurements, please refer to How to Measure',
  sizeTxt4: 'Dress Standard Size Chart',
  sizeTxt5: 'Bust Waist Hip Standard Size Chart',
  sizeTxt6: 'Dress Length Chart (Hollow To Hem)',
  sizeTxt7: 'Some styles have ease added for your comfort. If you are in between sizes, it is suggested to call and speak to a stylist or order the dress in custom size.',
  sizeTxt8: 'The Hollow to Hem measurement for standard sized floor length dresses includes an extra 2 inches to fit high heels.',
  sizeTxt9: 'Have trouble with choosing size or Additional Requirements on size? <a href="{hrefContact}">Contact us.</a>',
  sizeTxt10: 'Dress Size Chart',
  sizeTxt11: 'Plus Size Dress Size Chart',
  miniShort: 'Mini<span>/Short</span>',
  kneeLen: 'Knee Length',
  teaLen: 'Tea Length',
  ankleLen: 'Ankle Length',
  floorLen: 'Floor Length',
  dressLen: 'Dress Length',
  reference: 'For your reference only',
  eurSize: 'Europe Size',
  usSize: 'US Size',
  euSize: 'EU Size',
  ukSize: 'UK Size',
  auSize: 'AU Size',
  cupSize: 'Cup Size',
  shoulder: 'Shoulder',
  toBust: 'Shoulder to Bust',
  sizeTxt12: "Please do check Bust, Waist and Hips when choosing size. To know your girl's measurements, please refer to How to Measure",
  sizeTxt13: "Flower Girl Dresses",
  sizeTxt14: "size depends on the items. Please check the product details page for the exact size.",
  sizeTxt15: 'Flower Girl Dresses Size Chart',
  sizeTxt16: 'Junior Bridesmaid Dresses Size Chart',
  sizeTxt17: 'Jacket & Wrap Size Chart',
  sizeTxt18: 'Size chart is provided as a general sizing guide only. Specific measurements vary with each style.',
  sizeTxt19: 'Women & Men Footwear Size Chart',
  sizeTxt20: 'Dance Shoes Size Chart',
  sizeTxt21: 'Junior Bridesmaid Dresses size depends on the items. Please check the product details page for the exact size.',
  bustText1: "This is a measurement of the fullest part of your bust or body circumference at the bust. It is a body measurement which measures the circumference of a woman's torso at the level of the breasts.",
  bustText2: "Wrap the tape around the fullest part of your bust and center the tape on your back so it's levelled all the way around.",
  bustText3: "<strong>*Tips:</strong><br/>This isn't your bra size!<br/>Your arms should be relaxed, and down at your sides.<br/>Wear the bra you're planning to wear with your dress when taking this.",
  waistText1: "This is a measurement of your natural waistline, or the smallest part of your waist.",
  waistText2: "Run tape around natural waistline, keeping tape parallel with floor. Bend to one side to find natural indentation in torso. This is your natural waist.",
  hipsText1: "This is a measurement around fullest part of your buttocks.",
  hipsText2: 'Wrap tape around the fullest part of your hips, which is usually 7-9" below your natural waistline. Keep tape parallel with floor all the way around.',
  hemText1: "This is the distance from your hollow to the hem of the dress.",
  hemText2: "Hold the measuring tape at your hollow while a friend pulls it down to the length you want your dress.",
  hemText3: "<strong>*Tips:</strong><br/>When taking this measurement, stand straight with your feet together.<br/>We define knee length as the area just below your knee caps.<br/>Measuring too high may result in a dress that is too short.<br/>If this is a floor length dress please make sure you measure without wearing shoes.",
  shoulderText1: "This is the distance from the edge of the left shoulder across to the prominent neck bone located at the center of the back of the neck continuing to the edge of the right shoulder.",
  shoulderText2: 'Place the tape on "top" of the shoulders. Measure from the edge of the left shoulder across to the prominent neck bone located at the center of the back of the neck continuing to the edge of the right shoulder.',
  sleeveText1: "This is the measurement from your shoulder seam to where you would like your sleeve to end.",
  sleeveText2: "Measure from your shoulder seam to desired sleeve length with your arm relaxed by your side to get the best possible measurement.",
  sleeveText3: "<strong>*Tips:</strong><br/>Measure with your arm slightly bent.",
  armscyeText1: "This is the measurement of your armhole.",
  armscyeText2: "<strong>*Tips:</strong><br/>In order to take your armscye measurement, you must wrap the measuring tape over the top of your shoulder and around under your armpit.",
  armText2: "<strong>*Tips:</strong><br/>Measure with the muscle relaxed.",
  sizeTxt22: 'Please carefully follow our ',
  sizeTxt23: ' guide when taking measurements.',
  sizeTxt24: 'Wrap Size Chart',
  sizeTxt25: 'Plus Size Wrap Size Chart',
  sizeTxt26: 'Bra Size Chart',
  sizeTxt27: 'The Hollow to Floor measurement of our standard dresses already includes 2 inches (ca.5 cm) extra to fit high heels.',
  measureSize: 'Measurements Size',
  underBust: 'Under Bust',
  bustGap: 'Fluctuation Bust Gap',
  oneSize: 'One-Size',
  //color chart
  "Dark Navy": "Dark Navy",
  "Navy Blue": "Navy Blue",
  "Dusty Blue": "Dusty Blue",
  "Ink Blue": "Ink Blue",
  "Stormy": "Stormy",
  "Slate Blue": "Slate Blue",
  "Royal Blue": "Royal Blue",
  "Ocean Blue": "Ocean Blue",
  "Ice Blue": "Ice Blue",
  "Blue": "Blue",
  "Light Blue": "Light Blue",
  "Sky Blue": "Sky Blue",
  "Mist": "Mist",
  "Cabernet": "Cabernet",
  "Burgundy": "Burgundy",
  "Red": "Red",
  "Mulberry": "Mulberry",
  "Rust": "Rust",
  "Watermelon": "Watermelon",
  "Papaya": "Papaya",
  "Sunset": "Sunset",
  "Peach": "Peach",
  "Light Pink": "Light pink",
  "Pearl Pink": "Pearl Pink",
  "Petal": "Petal",
  "Blushing Pink": "Blushing Pink",
  "Candy Pink": "Candy Pink",
  "Coral": "Coral",
  "Dusty Pink": "Dusty Pink",
  "Dusty Rose": "Dusty Rose",
  "Peacock": "Peacock",
  "Blush": "Blush",
  "Tahiti": "Tahiti",
  "Lilac": "Lilac",
  "Lavender": "Lavender",
  "Dusk": "Dusk",
  "Wisteria": "Wisteria",
  "Mauve": "Mauve",
  "Vintage-Mauve": "Vintage-Mauve",
  "Vintage Mauve": "Vintage Mauve",
  "Orchid": "Orchid",
  "Grape": "Grape",
  "Fuchsia": "Fuchsia",
  "Regency": "Regency",
  "Plum": "Plum",
  "Chocolate": "Chocolate",
  "Dark Green": "Dark Green",
  "Teal": "Teal",
  "Emerald": "Emerald",
  "Hunter Green": "Hunter Green",
  "Olive": "Olive",
  "Celadon": "Celadon",
  "Lime Green": "Lime Green",
  "Dusty Sage": "Dusty Sage",
  "Sage": "Sage",
  "Mint Green": "Mint Green",
  "Turquoise": "Turquoise",
  "Spa": "Spa",
  "Jade": "Jade",
  "Beige": "Beige",
  "Yellow": "Yellow",
  "Daffodil": "Daffodil",
  "Champagne": "Champagne",
  "Gold": "Gold",
  "Orange": "Orange",
  "Marigold": "Marigold",
  "Tangerine": "Tangerine",
  "Taupe": "Taupe",
  "Mushroom": "Mushroom",
  "Silver": "Silver",
  "White": "White",
  "Ivory": "Ivory",
  "Potrobello": "Potrobello",
  "Steel Grey": "Steel Grey",
  "Black": "Black",
  'colorNote': `Please bear in mind that photos might be slightly different from the actual item in terms of color, due to the lighting, shadow and photo enhancements during the photo shoot. Computer Screen Gama, Brightness and Contrast settings sometimes also lead to confusions.`,
  "Chiffon": "Chiffon",
  "Organza": "Organza",
  "Satin": "Satin",
  "Taffeta": "Taffeta",
  "Elastic Satin": "Elastic Satin",
  "Tulle": "Tulle",
  "Lace": "Lace",
  "Jersey": "Jersey",
  "Charmeuse": "Charmeuse",
  "Moss": "Moss",
  //contact us
  contactUs1: "NEED HELP?",
  contactUs2: "We welcome all questions about purchase, order and after-sales service. we have friendly representatives ready to help you.",
  contactUs3: `If you have any questions, please kindly visit our <a href="{hrefFaq}">FAQ</a> first. The Help Center provides answers to most common questions.`,
  contactUs4: "If you are considering a purchase",
  contactUs5: "In case you have a question or need further information before placing an order, you can contact us at any given time via Livechat at the bottom right of the page, in case our Livesupport representative aren't available, you can send an E-Mail 24/7, we will get back to you as soon as possible.",
  contactUs6: "If you have already made a purchase",
  contactUs7: "The service department is here to help you with inquiries regarding your current processing orders and items you have already received. Please note that all inquiries are handled thoroughly, no request is left unanswered, we try to answer each request within one working day.",
  contactUs8: "Create a Tickets",
  contactUs9: "Working Time: UTC 1AM to 11AM",
  contactUs18: "Please do not send any return items to the address above, returns sent to this address cannot be processed.",
  contactUs19: 'If you need to return a product, please contact customer service by ',
  contactUs20: 'submitting a ticket',
  preSale: 'PRE-SALES',
  afterSale: 'AFTER-SALES',
  whatsapp: 'Whatsapp',
  addressList: [
    'Hong Kong Office Information',
    'Company : DIY FASHION LIMITED',
    'Registration Number: 1994949',
    'Address : Unit D, 16/F, One Capital Place, 18 Luard Road, Wan Chai Hong Kong. <span>(This is not return address)</span>',
  ],
  //payment methods
  paymentInfo1: 'Enjoy your safe and convenient shopping experience on stacees.com!',
  paymentInfo2: 'We accept the following payment methods. You can choose one you like:',
  paymentInfo3: 'We accept the following credit and debit cards',
  creditDebit: 'CREDIT/DEBIT CARD:',
  //terms conditions
  overview: 'Overview',
  termCont1: "This website is operated by DIY FASHION LIMITED (collectively referred to as “DIY FASHION LIMITED,” “us,” “we,” or “our” also known as stacees.com. Throughout the site, the terms “we”, “us” and “our” refers to DIY FASHION LIMITED. DIY FASHION LIMITED offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here. The terms “STACEES” throughout the site refer to DIY FASHION LIMITED.",
  termCont2: "By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content. Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service. Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.",
  termCont3: "GOVERNING LAW-These Terms of Service and any separate agreements whereby we provide you shall be governed by and construed in accordance with the laws of Hong Kong",
  termCont4: "Online Store Terms",
  termCont5: "By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.",
  termCont6: "You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).",
  termCont7: "You must not transmit any malware, viruses or any code of a destructive nature.",
  termCont8: "A breach or violation of any of the Terms will result in an immediate termination of your Services.",
  termCont9: "General Conditions",
  termCont10: "We reserve the right to refuse service to anyone for any reason at any time.",
  termCont11: "You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.",
  termCont12: "You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.",
  termCont13: "The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.",
  termCont14: "Accuracy, Completeness And Timeliness Of Information",
  termCont15: "We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or timelier sources of information. Any reliance on the material on this site is at your own risk.",
  termCont16: "This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.",
  termCont17: "Modifications To The Service And Prices",
  termCont18: "Prices for our products are subject to change without notice. We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time. We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.",
  termCont19: "Products Or Services",
  termCont20: "Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.",
  termCont22: `We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.`,
  termCont23: "We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at any time without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.",
  termCont24: "We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.",
  termCont25: "Accuracy Of Billing And Account Information",
  termCont26: "We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.",
  termCont27: "You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.",
  termCont28: 'For more detail, please review our <a class="cr-bd" href="{hrefReturn}">Returns Policy.</a>',
  termCont29: "Third-Party Links",
  termCont30: "Certain content, products and services available via our Service may include materials from third-parties.",
  termCont31: "Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.",
  termCont32: "We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.",
  termCont33: "User Comments, Feedback And Other Submissions",
  termCont34: "If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.",
  termCont35: "We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libellous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.",
  termCont36: "You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libellous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.",
  termCont37: "Personal Information",
  termCont38: "Your submission of personal information through the store is governed by our Privacy Policy.",
  termCont39: "Errors, Inaccuracies And Omissions",
  termCont40: "Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).",
  termCont41: "We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.",
  termCont42: "Prohibited Uses",
  termCont43: "In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.",
  termCont44: "Disclaimer Of Warranties; Limitation Of Liability",
  termCont45: "We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.",
  termCont46: "We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.",
  termCont47: "You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.",
  termCont48: "You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchant ability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.",
  termCont49: "In no case shall DIY FASHION LIMITED, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.",
  termCont50: "Indemnification",
  termCont51: "You agree to indemnify, defend and hold harmless DIY FASHION LIMITED and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.",
  termCont52: "Severability",
  termCont53: "In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforce ability of any other remaining provisions.",
  termCont54: "Termination",
  termCont55: "The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.",
  termCont56: "These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.",
  termCont57: "If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).",
  termCont58: "Non-Disparagement. During the Term and thereafter, you agree to take no action which is intended, or would reasonably be expected, to harm the Company or its or their reputation or which would reasonably be expected to lead to unwanted or unfavourable publicity to the Company in case you haven’t heard back from us for at least 4 working days. If violating this term, we reserve us the right to terminate the contract without any compensation.",
  termCont59: "entire Agreement",
  termCont60: "The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.",
  termCont61: "These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).",
  termCont62: "Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.",
  termCont63: "Changes To Terms Of Service",
  termCont64: "You can review the most current version of the Terms of Service at any time at this page.",
  termCont65: "We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website.",
  termCont66: "It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.",
  termCont67: "Questions about Terms?",
  //privacyPolicy
  privacyCont1: `This website is operated by DIY FASHION LIMITED (collectively referred to as “DIY FASHION LIMITED,” “us,” “we,” or “our” also known as stacees.com. We are committed to protecting and respecting the privacy of your personal data. This privacy notice explains how your data is collected, used, transferred and disclosed by STACEES. It applies to data collected when you use our websites, when you interact with us through social media, email, or phone, or when you participate in our competitions or events. It covers:`,
  privacyCont2: 'The personal data we collect',
  privacyCont3: 'How we collect your data',
  privacyCont4: 'How we use your data',
  privacyCont5: 'Marketing preferences, adverts and cookies',
  privacyCont6: 'Links to other websites and third parties',
  privacyCont7: 'How we share your data',
  privacyCont8: 'Your rights',
  privacyCont9: 'Changes to this privacy notice',
  privacyCont10: 'How to contact us',
  privacyCont11: 'Who is STACEES',
  privacyCont12: 'STACEES is a leading online fashion retail company. We design, product, market and sell clothing, targeted at female consumers in almost every country in the world.',
  privacyCont13: `STACEES (collectively referred to as “STACEES”, “we”, “us” and “our” in this privacy notice) is the controller and responsible for your personal data collected through the www.stacees.com website (the “website”).`,
  privacyCont14: 'Details of our Data Protection Officer responsible for overseeing questions in relation to this privacy notice, and our details are set out in the “How to Contact Us” section at the end of this notice.',
  privacyCont15: 'Our commitment to you',
  privacyCont16: 'We take the protection of your personal data seriously and will process your personal data fairly, lawfully and transparently. This privacy notice describes the personal data we are collecting about you and how it is used.',
  privacyCont17: 'We will only collect and use your personal data for the following purposes, to:',
  privacyCont18: 'fulfil your order(s)',
  privacyCont19: 'keep you up to date with the latest offers and trends',
  privacyCont20: 'give you a better shopping experience',
  privacyCont21: 'help us to make our marketing more relevant to you and your interests',
  privacyCont22: 'improve our services',
  privacyCont23: 'meet our legal responsibilities',
  privacyCont24: 'How we keep your data safe and secure',
  privacyCont25: 'We have appropriate organizational safeguards and security measures in place to protect your data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed.',
  privacyCont26: 'The communication between your browser and our website uses a secure encrypted connection wherever your personal data is involved. We encrypt your data and store it on secure servers hidden behind a firewall.',
  privacyCont27: 'We require any third party who is contracted to process your personal data on our behalf to have security measures in place to protect your data and to treat such data in accordance with the law.',
  privacyCont28: 'In the unfortunate event of a personal data breach, we will notify you and any applicable regulator when we are legally required to do so.',
  privacyCont30: 'Personal data means any information about an individual from which that person can be identified. It does not include anonymized data, where the identity and identifying information has been removed.',
  privacyCont31: 'While our website is designed for a general audience, we will not knowingly collect any data from children under the age of 13 or sell products to children. If you are under the age of 13, you are not permitted to use or submit your data to the website.',
  privacyCont32: 'The following groups of personal data are collected:',
  privacyCont33: '<strong>Identity Data</strong> includes information such as: first name, last name, title, date of birth (optional), personal description (optional), photo (optional) and gender (optional).',
  privacyCont34: '<strong>Contact Data</strong> includes information such as: email address, billing address, delivery address, location, country, telephone number, loyalty program membership number, Amazon ID (if you log in with Amazon) and social media id (if you log in by social media).',
  privacyCont35: '<strong>Financial Data</strong> includes information such as: payment card details or PayPal account information.',
  privacyCont36: '<strong>Transaction Data</strong> includes information such as: details of your purchases and the fulfilment of your orders (such as basket number, order number, subtotal, title, currency, discounts, shipping, number of items, product number, single item price, category, tax etc.); payments to and from you and details of other products and services you have obtained from us, correspondence or communications with you in respect of your orders, and details of any rewards and bonuses awarded.',
  privacyCont37: '<strong>Technical Data</strong> includes information such as: details of the device(s) you use to access our services, your internet protocol (IP) address, login data, your username and password, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform.',
  privacyCont38: '<strong>Profile Data</strong> includes information such as: purchases or orders made by you, product and style interests, preferences, feedback, and survey responses.',
  privacyCont39: '<strong>Usage Data</strong> includes information such as: how and when you use our website/app, how you moved around it, what you searched for; website/app performance statistics, traffic, location, weblogs and other communication data; loyalty program activities; and details of any other STACEES products and services used by you.',
  privacyCont40: '<strong>Marketing and Communications Data</strong> includes information such as: your preferences in receiving marketing from us and our third parties and your communication preferences.',
  privacyCont41: 'We also collect, use and share <strong>Aggregated Data</strong> such as statistical or demographic data for any purpose. Aggregated Data may be derived from your personal data but is span not considered personal data as this data does <strong>not</strong> directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy notice.',
  privacyCont43: 'We may collect personal data about you in the following ways:',
  privacyCont44: '<strong>Direct interactions</strong> – you may give us your Identity, Contact, Financial, Transaction, Profile, and Marketing and Communications data (as described above) by filling in forms, entering information online or by corresponding with us by post, phone, email, telephone or otherwise. This includes personal data you provide, for example, when you:',
  privacyCont45: 'Create an account or purchase products on our website;',
  privacyCont46: 'Subscribe to our newsletter, discussion boards, social media sites or create wish lists;',
  privacyCont47: 'Enter a competition;',
  privacyCont48: 'Join an STACEES loyalty program;',
  privacyCont49: 'Complete a voluntary market research survey;',
  privacyCont50: 'Contact us with an enquiry or to report a problem (by phone, email, social media, or messaging service);',
  privacyCont51: 'Use the “refer a friend” function on our website; or',
  privacyCont52: 'When you log in to our website via social media.',
  privacyCont53: '<strong>Automated technologies or interactions</strong> – as you interact with our website, we may automatically collect the following types of data (all as described above): Technical Data about your equipment, Usage Data about your browsing actions and patterns, and Contact Data where tasks carried out via our website remain uncompleted, such as incomplete orders or abandoned baskets. We collect this data by using cookies, server logs and other similar technologies.',
  privacyCont54: '<strong>Third parties</strong> – we may receive personal data about you from various third parties, including:',
  privacyCont55: 'Technical Data from third parties, including analytics providers such as Google. Please see further information in the section entitled ‘Marketing preferences, adverts and cookies’.',
  privacyCont56: 'Technical Data from affiliate networks through whom you have accessed our website;',
  privacyCont57: 'Identity and Contact Data from social media platforms when you log in to our website using such social media platforms;',
  privacyCont58: 'Contact, Financial and Transaction Data from providers of technical, payment and delivery services.',
  privacyCont60: 'The legal basis for processing your personal data',
  privacyCont61: 'We will only collect and process your personal data where we have a legal basis to do so. As a data controller, the legal basis for our collection and use of your personal data varies depending on the manner and purpose for which we collected it.',
  privacyCont62: 'We will only collect personal data from you when:',
  privacyCont63: 'we have your consent to do so, or',
  privacyCont64: 'we need your personal data to perform a contract with you. For example, to process a payment from you, fulfil your order or provide customer support connected with an order, or',
  privacyCont65: 'the processing is in our legitimate interests and not overridden by your rights, or',
  privacyCont66: 'we have a legal obligation to collect or disclose personal data from you.',
  privacyCont67: 'Uses made of your personal data',
  privacyCont68: 'Your personal data is used by STACEES to support a range of different activities. These are listed in the table below together with the types of data used and the legal bases we rely on when processing them, including where appropriate, our legitimate interests. Please be aware that we may process your personal data using more than one lawful basis, depending on the specific activity involved. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.',
  privacyCont69: 'Purpose/Activity',
  privacyCont70: 'Type of data',
  privacyCont71: 'Lawful basis for processing including basis of legitimate interest',
  privacyCont72: 'To create an account and register you as a new customer (either directly or via social media).',
  privacyCont73: '• Identity',
  privacyCont74: '• Contact',
  privacyCont75: '• Performance of a contract with you',
  privacyCont76: 'To process and deliver your order including: recording your order details; keeping you informed about the order status; process payments and refunds, collect money owed to us; and assist fraud prevention and detection.',
  privacyCont77: '• Financial',
  privacyCont78: '• Transaction',
  privacyCont79: '• Necessary for our legitimate interests (e.g. to recover debts due to us)',
  privacyCont80: 'To manage our relationship with you, including: providing you with any information, products and services that you request from us; notifying you about changes to our services, terms and conditions or privacy notice; asking you to leave a review or take a survey.',
  privacyCont81: '• Profile',
  privacyCont82: '• Marketing and Communications',
  privacyCont83: '• Necessary for our legitimate interests (to keep our records updated and to study how customers use our products and services',
  privacyCont84: 'To enable you to take part in a competition, event, survey, or receive a reward for shopping with us.',
  privacyCont85: '• Usage',
  privacyCont86: '• Necessary for our legitimate interests (to study how customers use our products and services, to develop them and grow our business)',
  privacyCont87: '• Where you have decided to enter into a competition or event, for the performance of a contract with you',
  privacyCont88: 'To administer, protect and improve our business and our website/app, including: troubleshooting, data analysis, testing, system maintenance, support, data analysis, reporting and hosting of data; setting default options for you, such as language and currency. ',
  privacyCont89: '• Technical',
  privacyCont90: '• Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, and to detect and prevent fraud)',
  privacyCont91: '• Necessary to comply with a legal obligation',
  privacyCont92: 'To deliver relevant website content, online advertisements and information for you; and measure the effectiveness of the advertising provided.',
  privacyCont93: '• Necessary for our legitimate interests (to study how customers use our products and services, to develop them, to grow our business and to inform our marketing strategy)',
  privacyCont94: 'To use data analytics to: improve our website, products, services, marketing, customer relationships and experiences;',
  privacyCont29: 'and for market research, statistical and survey purposes.',
  privacyCont95: '• Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)',
  privacyCont96: 'To recommend products, services discounts and offers that may be of interest to you, including to send you such information by email, post or SMS.',
  privacyCont97: '• Necessary for our legitimate interests (to develop our products and services and grow our business) or Consent.',
  privacyCont98: "See further details in the section ‘Marketing preferences, adverts and cookies'",
  privacyCont99: 'To inform or remind you by email of any task carried out via our website which remains uncompleted, such as incomplete orders or abandoned baskets.',
  privacyCont100: '• Necessary for our legitimate interests (to improve the shopping experience of our customers)',
  privacyCont101: 'We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If we wish to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so. We may process personal data without your consent, in compliance with the above rules, where this is required or permitted by law.',
  privacyCont102: 'If you have any questions about how STACEES uses any of your personal data, please contact us at service@stacees.com or Chat with Us',
  privacyCont103: 'How long we keep your data for',
  privacyCont104: 'We will keep your personal data for no longer than is necessary for the purpose(s) it was provided for and to meet our legal obligations. Further details of the periods for which we retain data are available on request.',
  privacyCont106: 'Marketing - your preferences',
  privacyCont107: 'We may send you marketing communications and promotional offers:',
  privacyCont108: 'if you have opened an account with us or purchased goods from us, or registered for a promotion or event, and you have not opted out of receiving that marketing (in accordance with your preferences, as explained below);',
  privacyCont109: 'by email if you have signed up for email newsletters;',
  privacyCont110: 'if you have provided us with your details when you entered a competition and you have consented to receiving such marketing (in accordance with your preferences, as explained below).',
  privacyCont111: 'We may use your Identity, Contact, Technical, Transactional, Usage, Profile Data and Marketing and Communications Data to form a view on what we think you may like, or what may be of interest to you, and to send you details of products and offers which may be relevant for you.',
  privacyCont112: 'We will ask you for your preferences in relation to receiving marketing communications by email, post, SMS and other communication channels.',
  privacyCont113: 'From time to time we may also include with your order, inserts advertising goods, services or offers from other third-party companies that you may be interested in.',
  privacyCont114: 'In respect of third party marketing communications, we will obtain your express opt-in consent before we share your personal data with any third party for marketing purposes',
  privacyCont115: 'You will always have full control of your marketing preferences. If you do not wish to continue receiving marketing information from us (or any third party, if applicable) at any time:',
  privacyCont116: `you can unsubscribe or ‘opt-out’ by using the unsubscribe button and following the link included in the footer of any marketing email; or <a class="font_red" href="{hrefLogin}">account holders may withdraw their consent by simply logging in to My Account and editing your ‘Contact Preferences’.</a>`,
  privacyCont117: 'We will process all opt-out requests as soon as possible, but please note that due to the nature of our IT systems and servers it may take a few days for any opt-out request to be implemented.',
  privacyCont118: 'Cookies',
  privacyCont119: 'Our website uses cookies to distinguish you from other users of our website and to keep track of your visits. They help us to provide you with the very best experience when you browse our website and to make improvements to our website. They also help us and our advertising networks to make advertising relevant to you and your interests.',
  privacyCont120: 'You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of our website may become inaccessible or not function properly.',
  privacyCont121: 'Online ads',
  privacyCont122: 'We use online advertising to keep you aware of what we’re up to and to help you find our products. Like many companies, we may target STACEES banners and ads to you when you use other websites and apps, based on your Contact, Technical, Usage and Profile Data. We do this using a variety of digital marketing networks and ad exchanges, and a range of advertising technologies such as web beacons, pixels, ad tags, cookies, and mobile identifiers, as well as specific services offered by some sites and social networks, such as Facebook’s Custom Audience Service.',
  privacyCont123: 'Our use of analytics and targeted advertising tools',
  privacyCont124: "We use a range of analytics and targeted advertising tools to display relevant website content on our website and online advertisements on other websites and apps (as described above) to you, deliver relevant content to you in marketing communications (where applicable), and to measure the effectiveness of the advertising provided. For example, we use tools such as Google Analytics to analyze Google's interest-based advertising data and/or third-party audience data (such as age, marital status, life event, gender and interests) to target and improve our marketing campaigns, marketing strategies and website content. We may also use tools provided by other third parties, such as Facebook, Adroll, Springbot, Criteo and Bing to perform similar tasks, using your Contact, Technical, Usage and Profile Data.",
  privacyCont125: 'In order to opt out of targeted advertising you need to disable your ‘cookies’ in your browser settings (see Cookie Policy for details) or opt-out of the relevant third-party Ad Settings. For example, you can opt-out of the Google Display Advertising Features.',
  privacyCont126: 'The Digital Advertising Alliance (which includes companies such as Google, Responsys and Facebook) provides a tool called WebChoices that can perform a quick scan of your computer or mobile devices, find out which participating companies have enabled customized ads for your browser, and adjust your browser preferences accordingly.',
  privacyCont127: 'If you would like any further information about the data collected by these third parties or the way in which the data is used, please contact us.',
  privacyCont129: 'Our website may include links to and from the websites of our partner networks, advertisers and affiliates, or to social media platforms. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to their websites.',
  privacyCont131: 'We may disclose and share your personal data with the parties set out below:',
  privacyCont132: 'where you have consented for us to do so. For example, if you have consented to receive marketing materials from third parties, or in respect of third parties’ (including co-branded or jointly promoted) products and services, we may pass your data on to the relevant third parties for the purpose of sending you such marketing communications; to business partners, suppliers, sub-contractors and other third parties that we use in connection with the running of our business for the purposes set out in the table above in the section ‘How we use your data’, such as:',
  privacyCont133: 'third party service providers that we engage to provide IT systems and software, and to host our website;',
  privacyCont134: 'third-party payment processing services (including Authorize.net, PayPal, Braintree, and Card Connect) to process your payment to us. We do not store your credit/debit card information;',
  privacyCont135: 'third party service providers that we engage to deliver goods you have ordered;',
  privacyCont136: 'third party service providers that we engage to send emails and postal mail on our behalf including in relation to incomplete orders or abandoned baskets, or marketing communications, to provide data cleansing services and to provide marketing and advertising services;',
  privacyCont137: 'analytics and search engine providers that assist us in the improvement and optimization of our website;',
  privacyCont138: 'affiliate networks through whom you have accessed our website;',
  privacyCont139: 'to any third party to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.',
  privacyCont140: 'to protect our customers and website from fraud and theft, we may share personal data that is required to make identity checks and personal data that we obtain from making identity checks (including data relating to your age, name and location), together with account information, with organizations (including law enforcement agencies), involved in fraud prevention and detection and credit risk reduction. Please note that these third parties may retain a record of the information that we provide to them for this purpose;',
  privacyCont141: 'if we are under a duty to disclose or share your personal data in order to comply with any legal obligation; or',
  privacyCont142: 'to our professional advisers including lawyers, bankers, auditors and insurers who provide consultancy, banking, legal, insurance and accounting services. ',
  privacyCont143: 'Your data and countries outside of Europe',
  privacyCont144: 'The personal data we collect from you may be transferred to, and stored at, destinations outside the European Economic Area ("EEA") using legally-provided mechanisms to lawfully transfer data across borders. It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers. Such staff may be engaged in, among other things, the fulfilment of your order, the processing of your payment details and the provision of support services. We will take all steps necessary to ensure that your data is treated securely and in accordance with this Privacy Notice.',
  privacyCont145: 'Whenever we transfer personal data outside the EEA, we will ensure a similar degree of protection is afforded to it by ensuring appropriate safeguards, as required by law, are in place. This may include using specific contractual clauses approved by the European Commission which give personal data the same protection as it has in Europe. More information about these is available here http://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX:32010D0087',
  privacyCont146: 'Please contact us if you want further information on the countries to which we may transfer personal data and the specific mechanism used by us when transferring your personal data outside the EEA.',
  privacyCont148: 'You have several rights under the data privacy legislation. This includes, under certain circumstances, the right to:',
  privacyCont155: 'object to automated decision making',
  privacyCont156: `Brief details of each of these rights are set out below. If you wish to exercise any of these rights, please email us at privacy@Stacees.com or Chat with Us.`,
  privacyCont157: 'Request access to your personal data',
  privacyCont158: 'You have the right to obtain a copy of the personal data we hold about you and certain information relating to our processing of your personal data.',
  privacyCont159: 'Request correction of your personal data',
  privacyCont160: 'You are entitled to have your personal data corrected if it is inaccurate or incomplete. You can update your personal data at any time by logging into your account and updating your details directly, or by emailing us at privacy@Stacees.com or Chat with Us',
  privacyCont161: 'Request erasure of your personal data',
  privacyCont162: 'This enables you to request that STACEES delete your personal data, where there is no good reason for us continuing to process it. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.',
  privacyCont163: 'Request restriction of processing of your personal data',
  privacyCont164: 'You have a right to ask STACEES to suspend the processing of your personal data in certain scenarios, for example if you want us to establish the accuracy of the data, or you have objected to our use of your data, but we need to verify whether we have overriding legitimate grounds to use it. Where processing is restricted, we are allowed to retain sufficient information about you to ensure that the restriction is respected in future.',
  privacyCont165: 'Request the transfer of your personal data',
  privacyCont166: 'You have the right to obtain a digital copy of your personal data or request the transfer of your personal data to another company. Please note though that this right only applies to automated data which you initially provided consent for us to use or where we used the data to perform a contract with you.',
  privacyCont167: 'Object to processing of your personal data',
  privacyCont168: 'You have the right to object to the processing of your personal data where we believe we have a legitimate interest in processing it (as explained above). You also have the right to object to our processing of your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your data which override your rights and freedoms.',
  privacyCont169: 'Object to automated decision making and profiling',
  privacyCont170: 'You have the right to object to the automated processing of your personal data without human intervention. This form of processing is permitted where it is necessary as part of our contract with you, providing that appropriate safeguards are in place or your explicit consent has been obtained.',
  privacyCont171: 'We will try to respond to all legitimate requests within one month. Occasionally, it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated. We may need to request specific information from you to help us confirm your identity and ensure your right to exercise any of the above rights. This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it.',
  privacyCont172: 'Right to lodge a complaint',
  privacyCont173: `If you have any concerns or complaints regarding the way in which we process your data, please email us directly at service@stacees.com or Chat with Us. You also have the right to make a complaint to the ICO (the data protection regulator in the UK). We would, however, appreciate the chance to deal with your concerns before you approach the ICO, so please do contact us in the first instance.`,
  privacyCont175: 'From time to time we may change this privacy notice. If there are any significant changes we will post updates on our website, applications or let you know by email.',
  privacyCont176: `We welcome feedback and are happy to answer any questions you may have about your data. Please send any questions, comments or requests for more information to service@stacees.com or Chat with Us. This Privacy Notice was last updated on September 22, 2022.`,
  //mix-match-bridesmaid
  mixMatchTit: 'Mix & Match Bridesmaid Dresses in Colors and Styles',
  maxBrid: 'MIX AND MATCH BRIDESMAID DRESSES',
  maxBrid1: 'HOW TO: MIX & MATCH BRIDESMAIDS',
  maxBrid2: "<p>When choosing wedding party attire, it may seem like everyone involved has an opinion. If you're </p><p>hoping to please the whole crew (while looking super stylish), consider mismatched bridesmaid </p><p>dresses. When executed correctly, mismatched bridesmaid dresses will help your squad stand out, </p><p>Here, we cover everything you need to know about flawlessly coordinating mismatched bridesmaid </p><p>dresses. Plus, we've included a shoppable guide featuring the most popular colors.</p>",
  maxBrid3: 'shop bridesmaid dresses',
  maxBrid4: 'Different Style (Same Color!)',
  maxBrid5: 'The most common type of mix and match bridal party features different dress styles but in the same color. It’s perfect for when you fall in love with a wedding color but want the popular mismatched look. This option is the easiest to coordinate and will allow your bridesmaids to have a choice. Allowing your bridesmaids to choose their own style will let them feel more comfortable and confident. After all, different styles flatter different body types!',
  maxBrid6: `shop by color`,
  maxBrid7: 'Different Color (Same Style!)',
  maxBrid8: 'When you can’t pick just one color, why not feature multiple? This option is easier to coordinate than a mix of different dress styles and colors, as there is no risk of designs that clash. Simply choose a dress style that will flatter all of the maids in your entourage. Usually, an a-line silhouette works best. From there specify the colors that they can wear, but make sure there is an even spread! You don’t want all but one person to match. We recommend that the bride specify what color each person in the party should wear.',
  maxBrid9: 'shop this style',
  maxBrid10: 'Different Color AND Style Dresses',
  maxBrid11: 'So you want your girls to wear bridesmaid dresses in different styles and colors. No worries! While this option requires the most work, it will also lend the most unique look. First, you will need to determine which colors you plan to feature. We recommend 3 to 4, and make sure that they complement one another. For example, mix shades and hues of one color. Like blue, pink, or purple. Next is selecting the style of dresses. While you want gowns to flatter each of your girls, each gown should also complement one another. So aim for matching silhouettes, fabrics, or details. Otherwise, you will end up with a lineup that looks too busy or chaotic. The best bet is to feature similar silhouettes, waistlines, necklines, or straps.',
  maxBrid12: 'Tips for Coordinating Mismatched Bridesmaid Dresses',
  maxBrid13: 'Even when your goal is a mismatched bridal party you want to look thoughtful, not thrown together. Follow these tips to make your mismatched maids appear intentional and well planned.',
  maxBrid14: 'Tie Your Bridesmaid Dresses Together with a Common Detail',
  maxBrid15: "<p>Choosing dresses that feature common details are </p><p>one of the best ways to ensure a cohesive look. This </p><p>could be matching fabric (chiffon, tulle, velvet), </p><p>similar necklines, matching colors, similar </p><p>silhouettes, and so on. You need at least one or two </p><p>details that match, otherwise, you risk a chaotic vibe </p><p>that's hard on the eyes.</p>",
  maxBrid16: "<p>We always recommend sticking to the same length </p><p>dress. Mixing short and long dresses rarely works </p><p>out! Especially when you consider that each length </p><p>lends a different vibe. Long dresses are often </p><p>considered more formal, while short dresses are a </p><p>little more casual. You don't want your girls to look </p><p>confused about the occasion.</p>",
  maxBrid17: 'Be Thoughtful About Your Wedding Color Palette',
  maxBrid18: `<p>When selecting colors for your bridesmaid party to </p><p>wear, don't just throw a palette together. Some colors </p><p>just don't mix, like warm and cool tones. Seek out </p><p>inspiration photos and <a href="{hrefFabric}" class="color_pink">Order Swatches</a> to test out </p><p>how the dress colors will look side by side. Once </p><p>you've found the perfect wedding palette, it's easier </p><p>than ever to shop for your bridesmaid dresses online </p><p>at STACEES.</p>`,
  maxBrid19: 'Give Your Bridesmaids Ample Direction!',
  maxBrid20: "<p>It's important that when allowing your girls to mix and </p><p>match colors and/or styles, that you still lend </p><p>direction. You should come up with an inspiration </p><p>board or sample photos that you can share and will </p><p>give them an idea of the look you're going for. Don't </p><p>expect your bridesmaids to find a dress (that you </p><p>love) themselves. You are responsible for </p><p>coordinating. One of the best ways to help them out </p><p>is to recommend your favorite websites to them.</p>",
  maxBrid21: 'Benefits of Mismatched Bridesmaid Dresses',
  maxBrid22: 'There are so many perks to mismatched bridesmaid dresses. The first, as we mentioned, is that your girls will know which styles best flatter their bodies. They can also dress according to their personal style, whether it be modest or sexier. Meaning they will feel comfortable and confident. In addition, and as important, is that it will allow some flexibility in regards to price. Not everyone may be able to afford the same dress. Allowing your girls to pick their own dress means that they can cater to their own budget, without any guilt or pressure.',
  maxBrid23: 'We understand that creating a mismatched bridal party is easier said than done! To help inspire you, check out these real Kennedy Blue weddings for colors and styles you can incorporate into your wedding.',
  maxBrid24: 'Conclusion',
  maxBrid25: "So now we give you some serious suggestions to consider. It's time to make a decision! Do you prefer a mismatched bridesmaid dress or the same style? Please contact us by email:",
  maxBrid26: " , tell us what you think and we'll be happy to help you.",
  maxBrid27: `shop by this mix colors`,
  shopBy: 'shop by colors',
  shopByTxt1: `Find colors that complement your wedding palette or mix and match`,
  shopByTxt2: `Hey girl, busy shopping for bridesmaid dresses by color? Check out Stacees color bridesmaid dresses collection then! Being a professional gown-maker for over a decade, Stacees provides ladies with top quality dresses of fabulous design and impeccable details. Not only do we carry all size models but also an unimaginably wide variety of color choices. Common colors for bridesmaid dresses are available such as pink or purple. However, we also provide our customers with a select color swatch that allows your girls to stand out among all. We understand the difficulty of glancing through hundreds of bridesmaids dress shops online only for one or two design only. To be honest, there aren't many bridesmaid dress shops that guarantee both look and quality. Even if you're satisfied with the design and all, you must be intimidated by the incredibly high price later on. Determined to serve every bride-to-be wholeheartedly, Stacees is one of the best shops for bridesmaid dresses, meaning that there's no need to get anxious about anything bridal. All you need to do is to pick a couple of fancy color bridesmaid dresses for your friends and family and leave the rest to us. We are professional. And we never let our clients down.`,
  shopBridDress: 'Shop All {color} Bridesmaid Dresses',
  colorSwatch: `color swatches`,
  shopNow: 'shop now',
  allPinks: 'All Pinks',
  allBlues: 'All Blues',
  allYellows: 'All Yellows',
  allGreens: 'All Greens',
  allOranges: 'All Oranges',
  allPurples: 'All Purples',
  allReds: 'All Reds',
  allGreys: 'All Greys',
  //most loved
  mostLovedTit: 'Most loved wedding dresses',
  mostLoved1: 'Classic Vintage',
  mostLoved2: "Vintage wedding dresses for a timeless look <span>that'll forever be in style</span>",
  mostLoved3: 'Romantic Boho',
  mostLoved4: 'These designs are sexy and sophisticated <span>with elegant lace and an open back.</span>',
  mostLoved5: 'Glam Modern',
  mostLoved6: 'Glamorous wedding dresses often <span>have a few jaw-dropping details</span>',
  mostLoved7: `Our colors are carefully assorted to make mix & match a breeze`,
  mostLoved8: 'View More Styles',
  mostLoved9: 'Bridesmaids <span>Mix & Match</span>',
  //Order Cancellation
  orderTit: 'Order Cancellation',
  orderCan1: 'Your order begins processing immediately after you place your order. ',
  orderCan2: 'We understand that ordering a dress for your special occasion is an important undertaking, and our cancellation policy was created with this in mind. However, it is important to note that our dresses are made for each order individually,and once the creation process has begun the materials cannot be reused. During the order processing time,it may be possible to cancel your order. ',
  orderCan3: 'However, once the order moves into the shipping process, it cannot be cancelled.',
  orderCan4: 'In the unlikely event that you wish to cancel your order, you can',
  orderCan5: 'Orders cancelled within 12 hours of payment confirmation will be eligible for a partial refund consisting of full shipping cost and 90% of product purchase price.',
  orderCan6: 'Orders cancelled within 12-24 hours of payment confirmation will be eligible for a partial refund consisting of full shipping cost and 80% of product purchase price.',
  orderCan7: 'Orders cancelled within 24-48 hours of payment confirmation will be eligible for a partial refund consisting of full shipping cost and 50% of product purchase price.',
  orderCan8: 'Over 48H',
  orderCan9: 'Orders cancelled beyond 48 hours after payment confirmation will be eligible for a partial refund of full shipping cost only.',
  orderCan10: 'Once your order has been shipped, it can no longer be cancelled.',
  orderCan11: 'If you would like to cancel your order, please login to <a class="udline myorder" href="javascript:void(0)">My Orders</a>, select the order you need help with, and request cancellation. Or please click <a class="udline" href="{hrefContact}">Contact Us</a> to proceed.',
  orderCan12: 'Actions',
  orderCan13: "Time Frames",
  orderCan14: 'Amount Refunded',
  orderCan16: 'Shipping Cost',
  orderCan17: 'Cancellation',
  orderCan18: 'Within 12 hours of order confirmation',
  orderCan19: 'Within 12-24 hours of order confirmation',
  orderCan20: 'Within 24-48 hours of order confirmation',
  orderCan21: 'Over 48 hours, not shipped',
  orderCan22: 'none',
  orderCan23: 'Order shipped',
  //success
  check: 'Checkout',
  checkoutWait1: 'Please wait a moment',
  successInfo1: 'Order Received!',
  successInfo2: 'Please check your E-mail within 30 minutes.',
  successInfo3: "Please check and reply the confirmation email to make sure the information about your order is correct.The confirmation email will be sent to you within 30 minutes. If you never receive confirmation email,please check the junk email or contact us via",
  backHome: 'Back Home',
  pageNotFond: 'Page Not Found',
  //title
  logInTit: 'Log In or Create an Account',
  myOrderTit: 'My Orders at STACEES',
  myCouponsTit: 'My Coupons at STACEES',
  myAccTit: 'My Account at STACEES',
  myProfileTit: 'My Profile at STACEES',
  myAddressTit: "Address Book at STACEES",
  myTicketTit: 'My Tickets at STACEES',
  myPasswdTit: 'My Password at STACEES',
  myReviewTit: 'My Reviews at STACEES',
  createTicketTit: 'Create a Ticket at STACEES',
  shopColorTit: 'Shop Bridesmaid Dresses by Color',
  shopColorKword: 'bridesmaid dress colors, dusty blue bridesmaid dresses, dusty rose bridesmaid dresses, burgundy bridesmaid dresses, blue bridesmaid dresses, pink bridesmaid dresses, emerald green bridesmaid dresses, red bridesmaid dresses, purple bridesmaid dresses, yellow bridesmaid dresses, colors of bridesmaid dresses, white, black, bridesmaid dresses by color, gold bridesmaid dresses, champagne bridesmaid dresses',
  shopColorDesc: 'Shop bridesmaid dresses and bridesmaid gowns by color.Find the perfect bridesmaid dresses in over 75 colors. To match your wedding color palette. You can get fabric color swatches to view any of these colors in person to see real examples of how to coordinate wedding colors.',
  homeTit: 'Stacees Dresses and Fashion Accessories for special occasions | Bridesmaid Dresses | Wedding Dresses | Prom Dresses | Evening Dresses and more at stacees.com',
  homeKeyword: 'Wedding Dresses, Bride Gowns, party dresses, evening dresses, evening gowns, bridesmaid dresses, Special Occasion Dresses Shop',
  homeDesc: 'We offer bridal gowns, evening dresses, prom dresses, party dresses,cocktail dresses, bridesmaid dresses and flower girls dresses from our online shop, Huge Discount Wedding Accessories for sale! A variety of hot selling Dresses at reasonable prices are all in our website.',
  styleGalleryDesc: 'Great Collections for Wedding Dresses, Bridesmaid Dresses, Prom Dresses, Homecoming Dresses, Shoes and More at STACEES. View our style gallery and find your dream dress here!',
  studentbeans: 'Studentbeans',
  searchTit1: 'Shop All {name}',
  searchTit2: 'Unique {name}',
  searchTit3: 'Free Delivery And Returns. {name}',
  searchTit4: 'Hotsale {name}',
  searchTit5: 'Various {name} To Be Couture',
  searchTit6: 'All About My {name}',
  searchTit7: 'Various Stunning {name} Ideas',
  searchTit8: 'Unexpected {name} Design',
  searchTit9: 'The Best Place To Shop For Available {name}',
  searchTit10: '{name} Online',
  searchTit11: 'Buy Best {name} Online',
  searchDesc1: `Provide excellent {name} at STACEES with unexpected styles & sizes. Look the special part on your daughter's day with the perfect ensemble.`,
  searchDesc2: `Shop {name} on STACEES. Choose the perfect look for your day with these exquisite and flowy choices.`,
  searchDesc3: `Shop our amazing collection of {name} online in a variety of colors, designs, size & styles.`,
  searchDesc4: `You are lucky! Now shop stunning {name}, and dress your mother in STACEES, available in different color and styles.`,
  searchDesc5: `Check out what those stylish women wore when their children got married. {name} has an important role in every wedding.`,
  searchDesc6: `STACEES provides {name} that can fit any style or size.`,
  searchDesc7: `As with bridal fashion,{name} don't have many rules. Come on! Shop hotsale {name} at STACEES.`,
  searchDesc8: `Discover our unique {name} from STACEES  and be the perfect guest in the special day.`,
  mixKeyWord: 'mix and match bridesmaid dresses, bridesmaid separates, convertible bridesmaid dress, mismatched bridesmaid dresses, bridesmaid dresses same color different styles',
  mixDesc: 'How to mix and match bridesmaid dresses with colors, fabrics and styles? Get a unique look at our collection of bridesmaid dresses and separates to learn how to create a ombre wedding palette and have your wedding wear different styles, colors or shades!',
  orderDetailTit: 'View Details at STACEES',
  paymentTit: 'Payment Success',
  emailReceive: 'Please help us know what emails would you like to receive?',
  subscribe: 'Subscribe',
  //accessoires
  accessInfo: 'Modern, ultra feminine jewelry & accessories for your big day & long after.',
  wrapShawls: 'Wraps & Shawls',
  bridalGloves: 'Bridal Gloves',
  hats: 'Hats',
  handbags: 'Handbags',
  fabricSwatch: 'FABRIC SWATCH',
  hairAccess: 'Hair Accessories',
  sashesBelts: 'Sashes & Belts',
  weddJewelery: 'Wedding & Party Jewelry',
  weddFlowers: 'Wedding Flowers',
  weddPett: 'Wedding Petticoats',
  accessTit: 'Wedding & Special Occasion Accessories Trends & Styles',
  accessKeyWd: 'Wedding Accessories, Special Occasion Accessories',
  accessDesc: 'Looking for accessories inspiration for an upcoming event or wedding, Shop the top fashion accessories trends, most popular styles, and newest looks of veils, headpieces, clutches, Pins, Combs, jewelry and more at Stacees to match your dress now. High Quality and Fast Shipping',
  ok: 'OK',
  weddPartyDress: 'Wedding Party Dresses',
  GiftsDecor: 'Gifts & Decor',
  ticketStatus: {
    any: 'Any',
    new: 'New',
    open: 'Open',
    pending: 'Pending',
    solved: 'Solved',
    closed: 'Closed',
  },
  promLink:'/list/prom-dresses',
  homecomLink:'/list/homecoming-dresses',
  weddLink:'/list/wedding-dresses',
  brideLink:'/list/bridesmaid-dresses',
  motherLink:'/list/mother-of-the-bride-dresses',
  guestLink:'/list/wedding-guest-dresses',
  flowerLink:'/list/flower-girl-dresses',
  fabricLink:'/list/fabric-swatch',
  shoesLink:'/list/fashion-shoes',
  handbagLink:'/list/handbags',
  piecesLink:'/list/headpieces',
  jacketsLink:'/list/jackets-wraps',
  weddShoesLink:'/list/wedding-shoes',
  bridalLink:'/list/bridal-wedding-veils',
  danceLink:'/list/dance-shoes',
  jewelryLink:'/list/jewelry',
  hatsLink:'/list/hats',
  evenLink:'/list/evening-dresses'
}
